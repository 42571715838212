import React from 'react';
import { IQueueItemLayoutProps } from '../../../../components/notification-hub/notification-hub.models';
import { themedAssetUrl } from '../../../../lib/theme-asset-url';
import { Button, KeplerPoints, UnorderedList, UnorderedListItem } from '@keplerco/core';

export function MissionBriefingModalLayout(props: IQueueItemLayoutProps): JSX.Element {
  return (
    <React.Fragment>
      <div className="dialogBackgroundLayout" style={{ zIndex: 2 }}>
        <object data={themedAssetUrl('backgrounds/stars.background.svg')} type="image/svg+xml">
          <img src={themedAssetUrl('backgrounds/stars.background.svg')} alt="" />
        </object>
      </div>

      <div className="modalContent" style={{ position: 'relative', zIndex: 3 }}>
        <div className="dialogBodyLayout">
          <div className="badgeIconContainer">
            <object data="/badges/badge_MissionBriefing.svg" type="">
              <img src="/badges/badge_MissionBriefing.svg" alt="" />
            </object>
          </div>

          <h2 className="heading2" style={{ textAlign: 'center' }}>
            Mission Briefing
          </h2>

          <UnorderedList>
            <UnorderedListItem>The closer the planet, the more vital the skills that you develop</UnorderedListItem>

            <UnorderedListItem>Travel between planets learning new skills</UnorderedListItem>

            <UnorderedListItem>
              Earn
              <KeplerPoints forceFontWeight="bold" />
              for completing challenges
            </UnorderedListItem>
          </UnorderedList>
        </div>

        <footer className="dialogFooterLayout modalFooterLayout">
          <Button chubby type={'button'} filled arrow={false} onClick={props.onAction}>
            Let's do this!
          </Button>
        </footer>
      </div>
    </React.Fragment>
  );
}
