import { ThemeColours } from '@keplerco/core';
import React from 'react';

export function Dot({ fill = 'baby-blue' }: { fill: ThemeColours | undefined }): JSX.Element {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={fill} />
    </svg>
  );
}