import React from 'react';
import { LottieOutput } from '../../../../components/lottie-icon/lottie-icon';
import { IQueueItemLayoutProps } from '../../../../components/notification-hub/notification-hub.models';
import fireworkConfettiLottie from '../../../../assets/firework_confetti.lottie.json';
import { skillScoreRounding } from '../../../../lib/skill-score-rounding';
import { useNavigate } from 'react-router-dom';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Anchor, Button, colourString, scorePercentageRAGColour } from '@keplerco/core';

export function SkillScorePostAssessmentUpdatedModalLayout(props: IQueueItemLayoutProps): JSX.Element {
  const skillScorePercentage = props.amount ?? 0;

  return (
    <>
      <div className="dialogBackgroundLayout">
        <LottieOutput loop autoplay data={fireworkConfettiLottie} />
      </div>

      <div className="modalContent" style={{ textAlign: 'center' }}>
        <div className="dialogBodyLayout" color={'text'}>
          <div className="badgeIconContainer">
            <object data={'/badges/badge_JourneyComplete_Comet.svg'} type="image/svg+xml">
              <img src={'/badges/badge_JourneyComplete_Comet.svg'} alt="KP" />
            </object>
          </div>

          <h2 className="heading2">Stellar performance!</h2>

          <h6 className="subtitle">
            You've updated your skill score for <strong style={{ color: 'var(--text)' }}>{props.title}</strong>.
          </h6>

          <h2 className="heading2" style={{ color: colourString(scorePercentageRAGColour(skillScorePercentage)) }}>
            {skillScoreRounding(skillScorePercentage)}%
          </h2>
        </div>

        <footer className="dialogFooterLayout modalFooterLayout">
          <Button onClick={props.onAction} chubby type={'button'} filled arrow={false}>
            Got It, thanks!
          </Button>
        </footer>
      </div>
    </>
  );
}

export function SkillScorePreAssessmentUpdatedModalLayout(props: IQueueItemLayoutProps): JSX.Element {
  const skillScorePercentage = props.amount ?? 0;
  const navigate = useNavigate();

  return (
    <>
      <div className="dialogBackgroundLayout">
        <LottieOutput loop autoplay data={fireworkConfettiLottie} />
      </div>

      <div className="modalContent" style={{ textAlign: 'center' }}>
        <div className="dialogBodyLayout">
          <div className="badgeIconContainer">
            <object data={'/badges/badge_JourneyComplete_Comet.svg'} type="image/svg+xml">
              <img src={'/badges/badge_JourneyComplete_Comet.svg'} alt="KP" />
            </object>
          </div>

          <h2 className="heading2">Stellar performance!</h2>

          <h6 className="subtitle">
            Your Skill Score for <strong style={{ color: 'var(--text)' }}>{props.title}</strong> has been updated
          </h6>

          <h2 className="heading2" style={{ marginTop: 20, color: colourString(scorePercentageRAGColour(skillScorePercentage)) }}>
            {skillScoreRounding(skillScorePercentage)}%
          </h2>
        </div>

        <footer className="dialogFooterLayout modalFooterLayout" style={{ flexDirection: 'column', gap: 15 }}>
          <Button onClick={props.onAction} chubby type={'button'} filled arrow={false}>
            CONTINUE TO THE COURSE
          </Button>

          <Anchor
            onClick={() => {
              navigate(PagePath.learningJourneyBase);
              props.onAction();
            }}
          >
            GO BACK TO MY CORE SKILLS MAP
          </Anchor>
        </footer>
      </div>
    </>
  );
}
