import React, { useEffect, useRef, useState } from 'react';
import { useAppActions, useAppState } from '../../../../overmind';
import { QuestionnaireName } from '../../../../models/questionnaire';
import { AssessmentQuestionnairesCardsList, AssessmentQuestionnairesCardsListItem, AssessmentQuestionnairesFooter, AssessmentQuestionnairesSearchHeader, AssessmentQuestionnairesSearchHeaderColumn, AssessmentQuestionnairesWrapper } from './assessment-questionnaires.styles';
import { AssessmentQuestionnairesSkeleton } from './assessment-questionnaires.skeleton';
import { searchStringArrayByQuery } from '../../../../lib/search-string-array-by-query';
import { SkillAssessment } from '../../../../models';
import { IAssessmentQuestionnaireWidgetProps } from './assessment-questionnaires.models';
import { useInViewport } from '../../../../lib/use-in-viewport.hook';
import { Anchor, CheckboxCard, Searchfield } from '@keplerco/core';

export function AssessmentQuestionnaireWidget(props: IAssessmentQuestionnaireWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const errorRef = useRef<HTMLDivElement>(null);

  const [questionnaires, setQuestionnaires] = useState<QuestionnaireName[]>();
  const [selectedQuestionnaireSlugs, setSelectedQuestionnaireSlugs] = useState<string[]>([]);
  // const [questionnaireSlug, setQuestionnaireSlug] = useState<string | undefined>(props.assessmentSlug);
  const [skillAssessment, setSkillAssessment] = useState<SkillAssessment | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const inViewport = useInViewport(errorRef);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const names = await actions.getQuestionnaireNames(companyVariables.slug!);

      if (!!props.assessmentSlug) {
        const skillAssessment = await actions.getSkillAssessment({ companySlug: companyVariables.slug!, assessmentSlug: props.assessmentSlug });
        if (!!skillAssessment) {
          setSelectedQuestionnaireSlugs(skillAssessment.questionnaireSlugs);
          setSkillAssessment(skillAssessment);
        }
      }

      setQuestionnaires(names);

      setIsLoading(false);
    }

    if (!questionnaires) getData();
  }, [questionnaires]);

  function toggleQuestionnaireSelection(slug: string) {
    setErrorMessage(void 0);
    if (selectedQuestionnaireSlugs.includes(slug)) {
      setSelectedQuestionnaireSlugs(prevSlugs => prevSlugs.filter(s => s !== slug));
    } else {
      setSelectedQuestionnaireSlugs(prevSlugs => [...prevSlugs, slug]);
    }
  }

  async function saveQuestionnaires() {
    if (selectedQuestionnaireSlugs.length > 0 && !!skillAssessment) {
      setErrorMessage(void 0);

      await actions.updateSkillAssessment({
        ...skillAssessment,
        companySlug: companyVariables.slug!,
        questionnaireSlugs: selectedQuestionnaireSlugs,
      });

      props.onStepComplete();
    } else setErrorMessage(`Please select at least one questionnaire to continue`);
  }

  useEffect(() => {
    if (!!errorMessage && !inViewport) setTimeout(() => errorRef.current?.scrollIntoView());
  }, [errorMessage]);

  if (!questionnaires || isLoading) return <AssessmentQuestionnairesSkeleton />;

  return (
    <AssessmentQuestionnairesWrapper>
      <AssessmentQuestionnairesSearchHeader>
        <AssessmentQuestionnairesSearchHeaderColumn>
          <Searchfield value={searchQuery} responsive onInput={value => setSearchQuery(value)} />
        </AssessmentQuestionnairesSearchHeaderColumn>

        <AssessmentQuestionnairesSearchHeaderColumn></AssessmentQuestionnairesSearchHeaderColumn>
      </AssessmentQuestionnairesSearchHeader>

      <AssessmentQuestionnairesCardsList>
        {questionnaires
          .filter(questionnaire => {
            if (!!searchQuery) {
              return searchStringArrayByQuery([questionnaire.name, questionnaire.skillSubTypeName], searchQuery);
            }

            return true;
          })
          .map(questionnaire => {
            const isSelected = selectedQuestionnaireSlugs.includes(questionnaire.slug);

            return (
              <CheckboxCard id={questionnaire.slug} key={questionnaire.slug} checked={isSelected} onChange={() => toggleQuestionnaireSelection(questionnaire.slug)}>
                <AssessmentQuestionnairesCardsListItem className="card" key={questionnaire.slug}>
                  <span className="caption1">{questionnaire.name}</span>
                  <span className="caption2">{questionnaire.skillSubTypeDescription}</span>
                </AssessmentQuestionnairesCardsListItem>
              </CheckboxCard>
            );
          })}
      </AssessmentQuestionnairesCardsList>

      <div className="fieldErrorMessage" ref={errorRef} style={{ marginTop: 5, visibility: !!errorMessage ? 'visible' : 'hidden' }}>
        {errorMessage}
      </div>

      <AssessmentQuestionnairesFooter>
        <Anchor arrow onClick={() => saveQuestionnaires()}>
          Next
        </Anchor>
      </AssessmentQuestionnairesFooter>
    </AssessmentQuestionnairesWrapper>
  );
}

