import React from 'react';
import { Bar, BarChart, CartesianGrid, Label, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { capitalCase, colourString } from '@keplerco/core';
import { ScoreItem } from './horizontal-bar-chart.models';
import { useAppState } from '../../../../overmind';
import { generateTicks } from './horizontal-bar-chart.helpers';
import { RechartsTooltip } from '../../../../components/charts/recharts-tooltip/recharts-tooltip';

function Bars(scoreItem: ScoreItem, type: 'highest' | 'lowest'): JSX.Element[] {
  return scoreItem && Object.keys(scoreItem).map((key) => {
    if (key === 'name') return <></>;
    return (
      <Bar
        key={`bar-${scoreItem.name}-${key}`}
        name={capitalCase(key)}
        dataKey={key}
        type='monotone'
        fill={type === 'highest' ? colourString(`baby-blue`) : colourString('orange')}
        animationDuration={1000}
        shape={<Rectangle
          radius={[0, 10, 10, 0]}
          height={10}
        />}
      />
    );
  });
}

export function HorizontalBarChart({ scores, type }: { scores: ScoreItem[], type: 'highest' | 'lowest' }): JSX.Element {
  const { companyVariables } = useAppState();
  return (
    <ResponsiveContainer height={scores.length * 50 + 100} width="100%">
      <BarChart
        accessibilityLayer
        data={scores}
        margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
        layout="vertical"
        barCategoryGap={5}
        barGap={50}
        maxBarSize={5}
      >
        <CartesianGrid
          horizontal={false}
          syncWithTicks
          stroke='var(--default)'
          strokeWidth={0.3}
          strokeDasharray={'3 3'}
        />
        <XAxis
          type="number"
          stroke='var(--borders)'
          domain={companyVariables.useLevels ? [companyVariables.minLevel, companyVariables.maxLevel] : [0, 100]}
          ticks={companyVariables.useLevels ? generateTicks(companyVariables.minLevel, companyVariables.maxLevel) : generateTicks(0, 100)}
          strokeWidth={0.5}
          tickMargin={10}
          height={50}
          tick={{ fill: 'var(--text)', fontSize: 15 }}
        >
          <Label value={companyVariables.useLevels ? 'Level' : 'Percentage'} position="insideBottom" offset={-10} fontSize={15} fill='var(--text)' />
        </XAxis>
        <YAxis
          type="category"
          dataKey={'name'}
          width={150}
          tickLine={false}
          tickMargin={160}
          axisLine={false}
          tick={{ fill: 'var(--text)', fontSize: 15, textAnchor: 'left' }}
        />
        <Tooltip cursor={false} content={<RechartsTooltip />} />
        {/*TODO: hide until needed <Legend iconType='circle' layout="horizontal" verticalAlign="top" align="left" /> */}
        {Bars(scores[0], type)}
      </BarChart>
    </ResponsiveContainer>
  );
}


