import React, { useState, useEffect } from 'react';
import { ButtonContainer, LabelAndTagsContainer, SelectedTagsContainer, SelectedTagsHeader, StyledCardFlat, StyledLabel, TagsWrapper } from './tag-input.styles';
import InputWithButton from './input-with-button';
import { Assignee, TagListItem } from '../../../models/tag';
import { AddIcon, PillButton, XIcon } from '@keplerco/core';

export interface Tag {
  id: string;
  name: string;
}

interface ITagProps {
  initialTags?: TagListItem[];
  onTagChange?: (tags: TagListItem[]) => void;
  searchTags?: (query: string) => Promise<TagListItem[]>;
  onCreateTag?: (tagName: string, assignees: Assignee[], source: 'input' | 'search') => Promise<void>;
  onRemoveTag?: (tagName: string) => Promise<void>;
  getAssignees?: (tagName: string) => Assignee[];
}

function TagInput({ initialTags = [], onTagChange, searchTags, onCreateTag, getAssignees, onRemoveTag }: ITagProps) {
  const [inputValue, setInputValue] = useState('');
  const [selectedTags, setSelectedTags] = useState<TagListItem[]>(initialTags);
  const [searchResults, setSearchResults] = useState<TagListItem[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchMessage, setSearchMessage] = useState('');

  useEffect(() => {
    if (inputValue !== '' && searchTags) {
      setIsSearching(true);
      searchTags(inputValue).then(tags => {
        setSearchResults(tags);
        if (tags.length > 0) {
          setSearchMessage(`We've found these existing tags that might match:`);
        } else {
          setSearchMessage('No matching tags were found. Create a new tag or try a different search term.');
        }
      });
    } else {
      setIsSearching(false);
    }
  }, [inputValue, searchTags]);

  useEffect(() => {
    setSelectedTags(initialTags);
  }, [initialTags]);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  async function handleAddTag() {
    if (inputValue.trim() !== '') {
      const assignees = getAssignees ? getAssignees(inputValue) : [];
      if (onCreateTag) {
        try {
          await onCreateTag(inputValue, assignees, 'input'); // Pass 'input' as source
          console.log(`Tag created successfully`);
        } catch (error) {
          console.error(`Failed to create tag: `, error);
        }
      }

      const newTag: TagListItem = { tagName: inputValue, isTagUsed: true };
      setSelectedTags(prevState => {
        const newTags = [...prevState, newTag];
        onTagChange && onTagChange(newTags);
        return newTags;
      });
      setInputValue('');
      setSearchResults([]);
    }
  }

  async function handleSelectTag(tag: TagListItem) {
    const assignees = getAssignees ? getAssignees(tag.tagName) : [];
    const alreadySelected = selectedTags.find(t => t.tagName === tag.tagName);
    if (alreadySelected) {
      if (onRemoveTag) {
        await onRemoveTag(tag.tagName);
      }

      setSelectedTags(prevState => {
        const newTags = prevState.filter(t => t.tagName !== tag.tagName);
        onTagChange && onTagChange(newTags);
        return newTags;
      });
    } else {
      if (onCreateTag) {
        try {
          await onCreateTag(tag.tagName, assignees, 'search');
          console.log(`Successfully added assignees to the tag`);
        } catch (error) {
          console.error(`Failed to add assignees to the tag: `, error);
        }
      }
      setSelectedTags(prevState => {
        const newTags = [...prevState, tag];
        onTagChange && onTagChange(newTags);
        return newTags;
      });
    }

    setInputValue('');
    setSearchResults([]);
  }

  return (
    <div>
      <InputWithButton inputLabel="Add a tag" inputValue={inputValue} onInputChange={handleInputChange} onButtonClick={handleAddTag} buttonText="CREATE NEW TAG" />
      {isSearching && (
        <StyledCardFlat className="card">
          <LabelAndTagsContainer>
            <StyledLabel>{searchMessage}</StyledLabel>
            <ButtonContainer>
              {searchResults.map(tag => (
                <PillButton key={tag.tagName} square animate active tiny activeTextColour="headings" backgroundColour="borders" slug={tag.tagName} label={tag.tagName} icon={<AddIcon size={10} />} onClick={() => handleSelectTag(tag)} />
              ))}
            </ButtonContainer>
          </LabelAndTagsContainer>
        </StyledCardFlat>
      )}
      <SelectedTagsContainer>
        {selectedTags.length !== 0 && <SelectedTagsHeader>Tags on this profile</SelectedTagsHeader>}
        <TagsWrapper>
          {selectedTags.map(tag => (
            <PillButton square tiny icon={<XIcon size={10} />} active activeTextColour="black" backgroundColour="grc_3" key={tag.tagName} onClick={() => handleSelectTag(tag)} label={tag.tagName} />
          ))}
        </TagsWrapper>
      </SelectedTagsContainer>
    </div>
  );
}

export default TagInput;

