import React from 'react';
import { IQueueItemLayoutProps } from '../../../../components/notification-hub/notification-hub.models';
import { levelNameAndIconMap } from './level-achieved-modal.levels';
import confettiLottie from '../../../../assets/confetti.lottie.json';
import { LottieOutput } from '../../../../components/lottie-icon/lottie-icon';
import { LevelAchievedModalChip } from './level-achieved-modal.styles';
import { BadgeIconChip, Button, colourString, levelColourType } from '@keplerco/core';

export function LevelAchievedModalLayout(props: IQueueItemLayoutProps): JSX.Element {
  const level = props.level ? Math.floor(props.level) : 1;
  const iconLevel = level > 5 ? 5 : level;
  const levelMetaData = levelNameAndIconMap[Math.max(iconLevel - 1, 0)];

  return (
    <>
      <div className="dialogBackgroundLayout">
        <LottieOutput loop autoplay data={confettiLottie} />
      </div>

      <div className="modalContent" style={{ textAlign: 'center' }}>
        <header className="dialogHeaderLayout modalHeaderLayout">
          <h2 className="heading2">{props.title ?? 'You crushed it!'}</h2>
        </header>

        <div className="dialogBodyLayout">
          <div className="badgeIconContainer">
            <object data={levelMetaData.iconUrl} type="image/svg+xml">
              <img src={levelMetaData.iconUrl} alt={levelMetaData.name} />
            </object>

            <BadgeIconChip colour={levelColourType(level)} value={level} />
          </div>

          <h2 className="heading2">{levelMetaData.name}</h2>

          <LevelAchievedModalChip className="subtitle" backgroundColour={levelColourType(level)}>
            Level {level}
          </LevelAchievedModalChip>

          <h6 className="subtitle" style={{ paddingTop: 15, color: colourString('accent-2') }}>
            You've just levelled up
          </h6>
        </div>

        <footer className="dialogFooterLayout modalFooterLayout">
          <Button onClick={props.onAction} chubby type={'button'} filled arrow={false}>
            Thanks!
          </Button>
        </footer>
      </div>
    </>
  );
}
