import { Button, ListItemLayout, ListLayout, Pager, SearchBar } from '@keplerco/core';
import React from 'react';
import { EntitySelectionListItem } from '../entity-selection-list-item/entity-selection-list-item';
import { EmptyState } from '../../empty-state/empty-state';
import { IEntitySelectionListProps } from '../entity-selection.models';

export function EntitySelectionList({ loading, label, entities, page, totalPages, onInput, onPageChange, button, dialogContained = false }: IEntitySelectionListProps): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
        <SearchBar
          loading={loading}
          label={label}
          onInput={event => onInput((event.target as HTMLInputElement).value)}
          responsive
        />

        {!!button && (
          <div>
            <Button type="button" theme="dark" loaderText={button.text} square onClick={button.onClick}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                {button.icon}
                {button.text}
              </div>
            </Button>
          </div>
        )}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        {!!totalPages ? (
          <React.Fragment>
            <ListLayout>
              {entities.map(entity => (
                <ListItemLayout key={entity.slug}>
                  <EntitySelectionListItem
                    {...entity}
                    loading={loading}
                    menuItems={entity.menuItems ?? []}
                    dialogContained={dialogContained}
                    onClick={entity.onClick}
                  />
                </ListItemLayout>
              ))}
            </ListLayout>

            {totalPages > 1 && (
              <Pager
                isLoading={loading}
                activePageNumber={page}
                pageCount={totalPages}
                onPageChange={event => {
                  if (event === page) return;
                  onPageChange(event);
                }}
              />
            )}
          </React.Fragment>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
}