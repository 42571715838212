import { Button, DropdownSearch, ListItemLayout, ListLayout } from '@keplerco/core';
import React from 'react';
import { IEntitySelectionDualListProps } from '../entity-selection.models';
import { EntitySelectionListItem } from '../entity-selection-list-item/entity-selection-list-item';
import { EmptyState } from '../../empty-state/empty-state';

export function EntitySelectionDualList({ loading, label, dropdownItems, entities, button }: IEntitySelectionDualListProps): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
        <DropdownSearch
          label={label}
          items={dropdownItems}
          disabled={loading}
          responsive
          multiple
        />

        {!!button && (
          <div>
            <Button type="button" theme="dark" isLoading={loading} square onClick={button.onClick}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                {button.icon}
                {button.text}
              </div>
            </Button>
          </div>
        )}
      </div>

      <div style={{ maxHeight: 400, overflowY: 'auto' }}>
        {!!entities.length ? (
          <ListLayout>
            {entities.map(entity => (
              <ListItemLayout key={entity.slug}>
                <EntitySelectionListItem
                  {...entity}
                  loading={loading}
                  menuItems={entity.menuItems ?? []}
                  dialogContained
                />
              </ListItemLayout>
            ))}
          </ListLayout>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
}