import React, { FormEvent, useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { LearnerSkillAssessment } from '../../../models/skill-assessment-config';
import { Button, DropdownSelect, DropdownSelectItem, Filters, Modal, PageHeader, Pager, SearchBar, SkillLevel, SkillPercentage } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { AssessmentType } from '../../../enums/assessment-type';
import { AssessmentCard, AssessmentCardSubtitle, AssessmentTitleWrapper, SkillGradingWrapper } from './learner-assessments.styles';
import { AppPageWrapper } from '../../../theme';
import { AssessmentsSearchRequest } from '../../../models/overmind/search-request';
import { CompletionStatus, FetchStatus, FetchType } from '../../../enums';
import { assessmentTypeItems, clearFilterItems, formatAssessmentTypeLabel, setFilterItems } from './learner-assessments.helpers';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { QueueItemPriority, QueueItemType } from '../../../components';
import { ConfirmationModalLayout } from '../../../widgets/layouts';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { LearnerAssessmentsSkeleton } from './learner-assessments.skeleton';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { getSubdomain } from '../../../lib/get-subdomain';

const pageSize = 10;

function sort(data: LearnerSkillAssessment[]): LearnerSkillAssessment[] {
  return data.sort((a, b) => {
    if (a.isActive) {
      if (!b.isActive) return -1;
      if (!a.score && !!b.score) return -1;
      if (!!a.score && !b.score) return 1;
      if (a.allowReassessment && !b.allowReassessment) return -1;
      if (!a.allowReassessment && b.allowReassessment) return 1;
      return 0;
    }
    if (!a.isActive) {
      if (!!b.isActive) return 1;
      if (!a.score && !!b.score) return -1;
      if (!!a.score && !b.score) return 1;
      if (a.allowReassessment && !b.allowReassessment) return -1;
      if (!a.allowReassessment && b.allowReassessment) return 1;
      return 0;
    }
    return 0;
  });
}

function page(data: LearnerSkillAssessment[], page: number): LearnerSkillAssessment[] {
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  return data.slice(start, end);
}

export default function LearnerAssessmentsPage() {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables, fetchState, settings } = useAppState();

  const subdomain = getSubdomain();

  function onClickDropdownItem(item: DropdownSelectItem) {
    setAssessmentDropdownItems(currentState => setFilterItems(currentState, item.value));
  }

  const [assessmentTypeDropdownItems, setAssessmentDropdownItems] = useState<DropdownSelectItem[]>(assessmentTypeItems?.map(item => ({ ...item, onClick: () => onClickDropdownItem(item) })) ?? []);
  const [request, setRequest] = useState<AssessmentsSearchRequest>({
    companySlug: companyVariables.slug,
    sortAscending: true,
    pageSize: 99999,
    page: 1,
  });
  const [data, setData] = useState<LearnerSkillAssessment[]>();

  const [pageCount, setPageCount] = useState<number>(1);
  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [pagedData, setPagedData] = useState<LearnerSkillAssessment[]>();

  const [reassessment, setReassessment] = useState<LearnerSkillAssessment | undefined>(undefined);

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: PagePath.learnerAssessments, type: FetchType.PageFetching });

      const response = await actions.getLearnerAssessments(request);

      setPageCount(!!response?.length ? Math.ceil(response.length / pageSize) : 0);

      const sortedResponse = sort(response ?? []);
      setData(sortedResponse);

      const pagedResponse = page(sortedResponse, activePageNumber);
      setPagedData(pagedResponse);

      actions.stopLoader(PagePath.learnerAssessments);
    }

    initPage();
  }, []);

  useEffect(() => {
    async function updatePage() {
      const response = await actions.getLearnerAssessments(request);

      setPageCount(!!response?.length ? Math.ceil(response.length / pageSize) : 0);

      const sortedResponse = sort(response ?? []);
      setData(sortedResponse);

      const pagedResponse = page(sortedResponse, activePageNumber);
      setPagedData(pagedResponse);

      actions.stopLoader(PagePath.learnerAssessments);
    }

    updatePage();
  }, [request]);

  function onInputHandler(event: FormEvent<HTMLInputElement>) {
    actions.startLoader({ path: PagePath.learnerAssessments, type: FetchType.Custom });
    const search = (event.target as HTMLInputElement).value;
    setRequest(current => ({ ...current, search, page: 1 }));
    setActivePageNumber(1);
  }

  function clearFilters(): void {
    setAssessmentDropdownItems(currentState => clearFilterItems(currentState));
    setRequest(currentState => ({ ...currentState, assessmentType: undefined, page: 1 }));
    setActivePageNumber(1);
  }

  function applyFilters() {
    const assessmentSelectedItem = assessmentTypeDropdownItems.find(item => item.selected)?.value;
    if (!assessmentSelectedItem) return;
    setRequest(currentState => ({ ...currentState, assessmentType: parseInt(assessmentSelectedItem), page: 1 }));
    setActivePageNumber(1);
  }

  function onPageChangeHandler(pageNumber: number) {
    const pagedResponse = page(data ?? [], pageNumber);
    setPagedData(pagedResponse);
    setActivePageNumber(pageNumber);
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.learnerAssessments} skeletonLoader={<LearnerAssessmentsSkeleton />}>
        <AppPageWrapper className="pageWrapper wrapper">
          <PageHeader title={subdomain.includes('lpi') ? 'My Assessments' : 'Assessments'} breadcrumbs={[{ name: subdomain.includes('lpi') ? 'My Assessments' : 'Assessments', url: PagePath.learnerAssessments }]} />


          <div className="row" style={{ marginTop: 20, display: 'flex', gap: 15 }}>
            <SearchBar label="Search" loading={fetchState[PagePath.learnerAssessments].status === FetchStatus.Active && fetchState[PagePath.learnerAssessments].type === FetchType.Custom} onInput={onInputHandler} />

            <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
              <DropdownSelect label="Type" items={assessmentTypeDropdownItems} />
            </Filters>
          </div>

          {!!pagedData?.length ? (
            pagedData?.map(assessment => {
              const isAssessmentNotStarted = assessment.completionStatus === CompletionStatus.NotStarted;
              const isAssessmentInProgress = assessment.completionStatus === CompletionStatus.InProgress;
              const isAssessmentCompleted = assessment.completionStatus === CompletionStatus.Completed;

              return (
                <AssessmentCard key={assessment.assessmentName} status={assessment.completionStatus ?? 0} className="card">
                  <AssessmentTitleWrapper>
                    <AssessmentCardSubtitle>{formatAssessmentTypeLabel(AssessmentType[assessment.assessmentType])}</AssessmentCardSubtitle>
                    {assessment.assessmentName}
                  </AssessmentTitleWrapper>

                  <SkillGradingWrapper>
                    {/* only show assess for assessments that are active and incomplete OR in progress, otherwise show the score */}
                    {(assessment.isActive && (isAssessmentNotStarted || isAssessmentInProgress)) ? (
                      <Button
                        type="button"
                        onClick={async () => {
                          const result = await actions.getSkillAssessmentConfiguration(assessment.assessmentSlug);
                          if (!!result) keplerNavigate(PagePath.analysisBase);
                        }}
                      >
                        Assess
                      </Button>
                    ) : (
                      <React.Fragment>
                        {companyVariables.useLevels ? (
                          <SkillLevel
                            level={assessment.score?.level ?? companyVariables.minLevel}
                            minLevel={companyVariables.minLevel}
                            maxLevel={companyVariables.maxLevel}
                            noLevel={!assessment.score}
                          />
                        ) : (
                          <SkillPercentage percentage={assessment.score?.percentage ?? 0} noPercentage={!assessment.score} />
                        )}
                      </React.Fragment>
                    )}

                    {/* only show reassess for assessments that are completed and allow reassessment */}
                    {(assessment.allowReassessment && isAssessmentCompleted) && (
                      <Button type="button" onClick={() => setReassessment(assessment)}>
                        Reassess
                      </Button>
                    )}
                  </SkillGradingWrapper>
                </AssessmentCard>
              );
            })
          ) : (
            <EmptyState badgeIconWidth={450} badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} />
          )}

          {pageCount > 1 && (
            <div style={{ marginTop: 20 }}>
              <Pager activePageNumber={activePageNumber} pageCount={pageCount} onPageChange={onPageChangeHandler} />
            </div>
          )}
        </AppPageWrapper>

        <Modal open={!!reassessment} onClose={() => setReassessment(undefined)}>
          {!!reassessment && (
            <ConfirmationModalLayout
              title="Confirm Reassessment"
              subtitle={`Are you sure you want to redo ${reassessment?.assessmentName}? If you proceed, your previous score will be overwritten with your new score...`}
              submitButtonText="Confirm"
              onClickSubmit={async () => {
                if (!settings) {
                  return actions.addNotification({
                    active: true,
                    id: crypto.randomUUID(),
                    type: QueueItemType.Error,
                    priority: QueueItemPriority.Toast,
                    title: 'There was an error triggering reassessment',
                    message: 'The system was unable to retrieve your email address. Please refresh the page and try again.',
                  });
                }

                actions.startLoader({ path: PagePath.learnerAssessments, type: FetchType.Sending });
                await actions.resetAssessment({ assessmentSlug: reassessment.assessmentSlug, userIds: [settings.email] });
                actions.getSkillAssessmentConfiguration(reassessment.assessmentSlug);
                actions.stopLoader(PagePath.learnerAssessments);
              }}
              onClickCancel={() => setReassessment(undefined)}
            />
          )}
        </Modal>
      </PageLoaderLayer>
    </React.Fragment>
  );
}
