import React from 'react';
import { IActiveAssessmentListFocusPanelLayoutProps } from './active-assessment-focus-panels.models';
import { Anchor, Chip, FocusPanelHeader, ListItemLayout, ListLayout } from '@keplerco/core';

export function ActiveAssessmentListFocusPanelLayout(props: IActiveAssessmentListFocusPanelLayoutProps): JSX.Element {
  return (
    <div className="panelContent">
      <FocusPanelHeader title="Assessment progress" subtitle="The assessment results will be available once everyone has completed the process." />

      <br />

      <div className="dialogBodyLayout" style={{ paddingBottom: 65 }}>
        <ListLayout>
          {props.activeAssessmentList.map(activeAssessment => (
            <ListItemLayout key={activeAssessment.slug} onClick={() => props.onClickAssessment(activeAssessment.slug!)}>
              <div className="card">
                <div className="cardListItemContentLayout">
                  <div className="cardListItemBodyLayout directionRow">
                    {activeAssessment.name}
                    <Chip label="Active" backgroundColour="a" />
                  </div>
                </div>
              </div>
            </ListItemLayout>
          ))}
        </ListLayout>
      </div>

      <footer className="panelFooter">
        <Anchor onClick={props.onClickBack}>Back</Anchor>
      </footer>
    </div>
  );
}

