import React, { useEffect, useState } from 'react';
import { DropdownItem, DropdownSearch, FocusPanel, ListItemLayout, ListLayout } from '@keplerco/core';
import { useAppActions, useAppState } from '../../../../../overmind';
import { ICourseMappingCourseSkillConditionProps } from '../course-mapping-course.models';
import { CourseMappingResponseSkill } from '../../../../../models/overmind/learning-management';
import styles from './course-mapping-course-conditions.module.css';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../../lib/permissions.helpers';
import { FetchType, OrganizationLevelType, SortField } from '../../../../../enums';
import { createLevelsArray } from '../../../../../lib/create-levels-array';
import { EntitySelectionListItem } from '../../../../../components/entity-selection/entity-selection-list-item/entity-selection-list-item';
import { CompanyEntityListItemResponse } from '../../../../../models/overmind/company-entity';
import { EntitiesCard } from '../../../../../components/entities-card.widget';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { ExplainerFocusPanelLayout } from '../../../../../widgets/layouts';

export function CourseMappingCourseSkillCondition({ skills, onClickAddSkill, onClickRemoveSkill, onClickAddLevel, onClickRemoveLevel }: ICourseMappingCourseSkillConditionProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  const organizationLevel = extractHighestOrganizationLevel(permissions?.learningManagement?.organizationLevels);
  const defaultRequest: CompanyEntitySearchParams = {
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 99999,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false,
  };

  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);
  const [skillToView, setSkillToView] = useState<CourseMappingResponseSkill>();

  useEffect(() => {
    function onClickDropdownItem(skill: CompanyEntityListItemResponse) {
      setDropdownItems(currentState => {
        const nextState = currentState.map(item => ({ ...item }));
        const item = nextState.find(temp => temp.value === skill.slug);
        if (!!item) {
          item.selected ? onClickRemoveSkill(skill.slug!) : onClickAddSkill({ name: skill.name, slug: skill.slug });
          item.selected = !item.selected;
        }
        return nextState;
      });
    }

    async function getDropdownItems() {
      const response = await actions.getCompanySkills(defaultRequest);

      const items: DropdownItem[] = response?.entities.map(skill => ({
        value: skill.slug,
        label: skill.name,
        onClick: () => onClickDropdownItem(skill),
        selected: skills?.some(temp => temp.slug === skill.slug),
      })) ?? [];
      setDropdownItems(items);
    }

    async function getData() {
      actions.startLoader({ path: PagePath.learningManagementCourseMappingCourse, type: FetchType.PageFetching });
      await getDropdownItems();
      actions.stopLoader(PagePath.learningManagementCourseMappingCourse);
    }

    getData();
  }, []);

  useEffect(() => {
    setDropdownItems(currentState => currentState.map(item => ({
      ...item,
      selected: skills?.some(temp => temp.slug === item.value),
    })));
  }, [skills]);

  return (
    <React.Fragment>

      <EntitiesCard title="Skills" description="Courses assigned by skill will only appear once an assessment has been completed.">
        <DropdownSearch
          label="Search and assign skills"
          items={dropdownItems}
          multiple
          responsive
          dialogContained
        />

        {!!skills?.length && (
          <div className={styles.scrollContainer}>
            <ListLayout>
              {skills.map(skill => {
                const levelItems: DropdownItem[] = createLevelsArray(companyVariables.maxLevel, companyVariables.minLevel).map(level => ({
                  value: `Level ${level}`,
                  onClick: async () => {
                    skill.levels?.includes(level) ? onClickRemoveLevel(skill.slug!, level) : onClickAddLevel(skill.slug!, level);
                  },
                  selected: skill.levels?.includes(level),
                }));

                return (
                  <ListItemLayout key={skill.slug}>
                    <EntitySelectionListItem
                      title={skill.name}
                      subtitle={skill.description ?? 'No skill description provided'}
                      menuItems={[
                        {
                          label: 'Remove skill',
                          onClick: () => {
                            onClickRemoveSkill(skill.slug!);
                            setDropdownItems(currentState => (
                              currentState.map(item => {
                                const nextItem = { ...item };
                                if (nextItem.value === skill.slug) nextItem.selected = false;
                                return nextItem;
                              })
                            ));
                          }
                        },
                        {
                          label: 'View skill',
                          onClick: () => setSkillToView(skill)
                        }
                      ]}
                      levelItems={levelItems}
                      selectMultipleLevelItems
                    />
                  </ListItemLayout>
                );
              })}
            </ListLayout>
          </div>
        )}
      </EntitiesCard>

      <FocusPanel open={!!skillToView} backgroundStyle="clean" onClose={() => setSkillToView(undefined)}>
        {!!skillToView && (
          <ExplainerFocusPanelLayout
            supertitle="Learning Management"
            title={skillToView.name}
            description={skillToView.description ?? ''}
            onClose={() => setSkillToView(undefined)}
          />
        )}
      </FocusPanel>
    </React.Fragment>

  );
}
