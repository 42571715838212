import { Anchor, capitalCase, Pager, Table, TableColumn, TableRow } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { OrganizationLevelType, ScoreType } from '../../../enums';
import { useAppState, useAppActions } from '../../../overmind';
import { PermissionsEntity } from '../permissions-entity-dropdown/permissions-entity-dropdown.models';
import { EntityScoresSearchParams } from '../../../models/overmind/search-params';
import { HeatmapType, ScoreItem } from './heatmap.models';
import { SkeletonLoader } from '../../../components';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { getEntityTitle, transformScores } from './heatmap.helpers';
import { PagePath } from '../../../navigation/navigation.enums';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';

export function HeatmapWidget({ entity }: { entity: PermissionsEntity | undefined }): JSX.Element {
  const { companyVariables, dateRange } = useAppState();

  const actions = useAppActions();

  const keplerNavigate = useKeplerNavigate();

  const [scores, setScores] = useState<ScoreItem[]>([]);
  const [averageScores, setAverageScores] = useState<ScoreItem[]>([]);
  const [title, setTitle] = useState<HeatmapType>();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      if (!entity) return;

      setIsLoading(true);

      const startDate = dateRange?.from?.toJSON();
      const endDate = dateRange?.to?.toJSON();
      const organizationLevel = entity.organizationLevel;
      const slug = entity.entity?.slug;

      const searchParams: EntityScoresSearchParams = {
        startDate,
        endDate,
        organizationLevel: organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        page: undefined,
        pageSize: undefined,
        scoreType: title === 'Department' || title === 'Team' ? ScoreType.SkillSubType : ScoreType.Skill,
        scoreTypeIds: undefined,
        sortAscending: sortAscending,
      };

      //Set params to get available score types
      searchParams.page = 1;
      searchParams.pageSize = 99;
      const scoreEntities = await actions.analyticsGetAvailableScoreTypes(searchParams);

      const slugs: string[] = [];
      scoreEntities?.results.forEach(result => {
        slugs.push(result.slug ?? '');
      });

      searchParams.scoreTypeIds = slugs;

      //Set params to get scores
      searchParams.page = currentPageNumber;
      searchParams.pageSize = 10;
      if (organizationLevel === OrganizationLevelType.Team) searchParams.departmentSlug = slug; //Department overview
      if (organizationLevel === OrganizationLevelType.Learner) searchParams.teamSlug = slug; //Team overview

      const scoreResults = await actions.analyticsGetEntityScoresList(searchParams);
      setScores(transformScores(scoreResults?.results ?? [], title ?? '', companyVariables.useLevels));
      setTotalPages(scoreResults?.totalPages ?? 0);

      //Set params to get entity average scores
      searchParams.page = 1;
      searchParams.pageSize = 10;
      if (organizationLevel === OrganizationLevelType.Department) {
        searchParams.organizationLevel = OrganizationLevelType.Company; //Company overview
        setTitle('Department');
      }

      if (organizationLevel === OrganizationLevelType.Team) {
        searchParams.organizationLevel = OrganizationLevelType.Department; //Department overview
        searchParams.departmentSlug = slug;
        setTitle('Team');
      }

      if (organizationLevel === OrganizationLevelType.Learner) {
        searchParams.organizationLevel = OrganizationLevelType.Team; //Team overview
        searchParams.teamSlug = slug;
        setTitle('People');
      }

      const averageScores = await actions.analyticsGetEntityScoresList(searchParams);
      setAverageScores(transformScores(averageScores?.results ?? [], title ?? '', companyVariables.useLevels, true));

      setIsLoading(false);
    }

    getData();

  }, [entity, dateRange, currentPageNumber, sortAscending]);

  useEffect(() => {
    setTitle(getEntityTitle(entity?.organizationLevel));
  }, [entity?.organizationLevel]);

  function onPageChangeHandler(page: number) {
    if (page === currentPageNumber) return;
    setCurrentPageNumber(page);
  }

  function onSortHandler() {
    setSortAscending(prev => !prev);
  }

  function navigateTo(slug: string) {
    if (title === 'Department') keplerNavigate(
      `${PagePath.analyticsBase}${PagePath.analyticsDepartment
        .replace(':companySlug', companyVariables.slug!)
        .replace(':departmentSlug', slug)}`
    );

    if (title === 'Team') keplerNavigate(
      `${PagePath.analyticsBase}${PagePath.analyticsTeam
        .replace(':companySlug', companyVariables.slug!)
        .replace(':teamSlug', slug)}`
    );

    if (title === 'People') keplerNavigate(
      `${PagePath.analyticsBase}${PagePath.analyticsPerson
        .replace(':companySlug', companyVariables.slug!)
        .replace(':personSlug', slug)}`
    );
  }

  return (
    isLoading ? (
      <SkeletonLoader height='450px' />
    ) : (
      <div className='card'>
        <h3 className="heading3" style={{ marginBottom: 30 }}>
          {`${title} ${companyVariables.useLevels ? 'skill levels' : 'percentages'}`}
        </h3>
        <Table
          emptyState={<EmptyState />}
          onSort={() => onSortHandler()}
          currentSortDirection={sortAscending ? 'Ascending' : 'Descending'}
          currentSortBy={title}
        >
          {[...scores, ...averageScores].map((score, index) => (
            <TableRow key={score.name} id={score.name ?? index} >
              {Object.entries(score).filter(([key]) => key !== 'slug').map(([key, value]) => (
                <TableColumn
                  key={key} id={key}
                  locked={key === `${title}`}
                  sortable={key === `${title}`}
                  label={capitalCase(key)}
                  heatmap={key !== `${title}` ? {
                    score: typeof value === 'number' ? Number(value) : String(value),
                    min: companyVariables.useLevels ? companyVariables.minLevel : undefined,
                    max: companyVariables.useLevels ? companyVariables.maxLevel : undefined,
                  } : undefined}
                  fontWeight={index === scores.length ? '700' : 'inherit'}
                  width={200}
                >
                  {key === `${title}` && 'slug' in score && typeof score.slug === 'string' && index !== scores.length ? (
                    <Anchor
                      hovertype="opacity"
                      textTransform="none"
                      onClick={event => {
                        event.stopPropagation();
                        navigateTo(score.slug as string);
                      }}
                    >
                      {value}
                    </Anchor>
                  ) : (
                    value
                  )}
                </TableColumn>
              ))}
            </TableRow>
          ))}
        </Table>

        {totalPages > 1 && (
          <div style={{ marginTop: 30 }}>
            <Pager activePageNumber={currentPageNumber} pageCount={totalPages} onPageChange={onPageChangeHandler} />
          </div>
        )}
      </div>
    )
  );
}