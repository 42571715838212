import React from 'react';
import {RatingConfirmationModalCard, RatingConfirmationModalCardWrapper } from './rating-confirm-endorsements-modal.styles';
import { TeamMemberReviewResource } from '../../../../../models/team-member-review-resource';
import { Anchor, Button, Chip, ThemeColours, colourString } from '@keplerco/core';
import { CompletionStatus } from '../../../../../enums';


interface ConfirmationEndorsementsModalLayoutProps {
  team: TeamMemberReviewResource[] | undefined;
  title: string;
  handleShowCompleted: () => void;
  handleShowNotCompleted: () => void;
  onClickSubmit: () => Promise<void>;
}

interface RenderCardProps {
  label: string;
  chipBackgroundColor: ThemeColours | undefined;
  count: number;
  buttonText: string;
  handleClick: () => void;
}

function RenderCard({ label, chipBackgroundColor, count, buttonText, handleClick }: RenderCardProps) {
  return (
    <RatingConfirmationModalCard>
      <Chip label={label} variant="tiny" backgroundColour={chipBackgroundColor} />
      <h1 className="heading1" style={{ margin: '20px 0 10px' }}>
        {count}
      </h1>
      <span className="caption1" style={{ marginBottom: '20px', color: colourString('accent-2') }}>
        {count === 1 ? 'Team Member' : 'Team members'}
      </span>
      <Anchor onClick={handleClick}>{buttonText}</Anchor>
    </RatingConfirmationModalCard>
  );
}

export function ConfirmationEndorsementsModalLayout({ 
  team, 
  title, 
  handleShowCompleted, 
  handleShowNotCompleted, 
  onClickSubmit 
}: ConfirmationEndorsementsModalLayoutProps): JSX.Element {
  const usersNotCompletedReview = team?.filter(i => !i.hasCompletedReview && i.completionStatus !== CompletionStatus.NoActionRequired).length ?? 0;
  const usersCompletedReview = team?.filter(i => i.hasCompletedReview).length ?? 0;

  return (
    <div className="modalContent">
      <header className="dialogHeaderLayout modalHeaderLayout" style={{ paddingBottom: 15 }}>
        <h4 className="heading4">{title}</h4>
      </header>

      <RatingConfirmationModalCardWrapper className="dialogBodyLayout">
        {usersNotCompletedReview > 0 && (
          <RenderCard 
            label="Not Started" 
            chipBackgroundColor="light" 
            count={usersNotCompletedReview} 
            buttonText={usersNotCompletedReview === 1 ? 'Finish Endorsement' : 'Finish Endorsements'} 
            handleClick={handleShowNotCompleted} 
          />
        )}
        <RenderCard 
          label="Completed" 
          chipBackgroundColor="apple" 
          count={usersCompletedReview} 
          buttonText={usersCompletedReview === 1 ? 'Change Endorsement' : 'Change Endorsements'} 
          handleClick={handleShowCompleted} 
        />
      </RatingConfirmationModalCardWrapper>

      <footer className="dialogFooterLayout modalFooterLayout">
        <Button type="button" filled onClick={onClickSubmit}>
          Yes, I'm done
        </Button>
      </footer>
    </div>
  );
}