import { SkillScoreComparison } from '../enums';

export function comparisonScoreTitle(skillScoreComparison: SkillScoreComparison): string {
  if (skillScoreComparison === SkillScoreComparison.FoPExpectedProficiency || skillScoreComparison === SkillScoreComparison.RoleToSkillExpectedProficiency) {
    return 'Expected proficiency';
  }

  // TODO - https://keplerandco.atlassian.net/browse/KPRDEV-2135
  // if (skillScoreComparison === SkillScoreComparison.FieldOfPractice) {
  //   return 'Role Average';
  // }

  return `${skillScoreComparison} average`;
}

