import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FetchType } from '../../../enums';
import { Department } from '../../../models/view/department';
import { useAppActions } from '../../../overmind';
import { TagType } from '../../../enums/tag';
import TagInput from '../../../components/form-elements/tags/tag-input';
import { DepartmentListItem, BaseSearchRequest } from '../../../models';
import { ManageDepartmentFocusPanelSkeleton } from './manage-department-focus-panel.skeleton';
import { Assignee, TagAssigneeRequest, TagListItem } from '../../../models/tag';
import { PagePath } from '../../../navigation/navigation.enums';
import { FocusPanelLoaderLayer } from '../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { Anchor, Button, CopyButton, FormControl, RequiredValidator, Textfield, useMatchScreenWidth } from '@keplerco/core';

interface IFormValues {
  departmentName: string;
}

export function ManageDepartmentFocusPanelCMSLayout(props: { department?: DepartmentListItem; onClick: () => void }): JSX.Element {
  const params = useParams<any>();
  const [tags, setTags] = useState<TagListItem[] | undefined>([]);
  const [search, setSearch] = useState('');
  const actions = useAppActions();

  const isMobile = useMatchScreenWidth('mobile');

  const defaultValues: IFormValues = {
    departmentName: props.department?.name ?? '',
  };
  const { handleSubmit, control } = useForm<any>({ mode: 'onBlur', defaultValues: defaultValues });

  async function onSubmitHandler(formValues: IFormValues) {
    actions.startLoader({ path: PagePath.administrationDepartments, type: FetchType.Sending });

    const department: Department = {
      slug: props.department?.slug ?? '',
      name: formValues.departmentName,
    };

    await actions.saveDepartment({ department: department, companySlug: params.companySlug! });

    props.onClick();

    actions.stopLoader(PagePath.administrationDepartments);
  }

  async function onClickRemoveTagHandler(tagName: string) {
    const assignees: TagAssigneeRequest[] = [
      {
        entitySlug: props.department?.slug ?? '',
        tagType: TagType.Department,
      },
    ];

    await actions.removeAssignees({ tagName, assignees, companySlug: params.companySlug! });
  }

  function getTagAssignees(): Assignee[] {
    return [
      {
        entitySlug: props.department?.slug ?? '',
        tagType: TagType.Department,
      },
    ];
  }

  async function getData() {
    actions.startLoader({ path: PagePath.administrationDepartments, type: FetchType.DialogFetching });

    const result = await actions.getAssigneeTags({ entitySlug: props.department?.slug ?? '', tagType: TagType.Department, companySlug: params.companySlug! });
    setTags(!!result ? result : []);

    actions.stopLoader(PagePath.administrationDepartments);
  }

  useEffect(() => {
    getData();
  }, []);

  async function onClickCreateTagHandler(tagName: string, assignees: Assignee[], source: string) {
    if (source === 'input') {
      tagName = search;
      assignees = [
        {
          entitySlug: props.department!.slug,
          tagType: TagType.Department,
        },
      ];
    }

    await actions.addAssignees({ tagName, assignees, companySlug: params.companySlug! });
  }

  function onTagChange(tags: TagListItem[]) {
    setTags(tags);
  }

  async function searchUserTags(query: string): Promise<TagListItem[]> {
    setSearch(query);
    const searchTagParams: BaseSearchRequest = {
      page: 1,
      pageSize: 15,
      search: query,
      sortAscending: true,
      sortField: 0,
      companySlug: params.companySlug!,
    };
    const paginatedResult = await actions.searchTags(searchTagParams);
    if (paginatedResult && paginatedResult.tags) {
      return paginatedResult.tags;
    }
    return [];
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationDepartments} skeletonLoader={<ManageDepartmentFocusPanelSkeleton />}>
      <div className="dialogContentLayout focusPanelContentLayout">
        <header className="dialogHeaderLayout" style={{ justifyContent: isMobile ? 'center' : 'space-between', paddingBottom: 30 }}>
          <h2 className="heading2">{!!props.department ? 'Update' : 'Create'} department</h2>

          {!!props.department && <CopyButton label="Department Id" value={props.department.slug} />}
        </header>

        <form id="saveDepartment" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="dialogBodyLayout">
            <FormControl
              control={control}
              rules={new RequiredValidator('Enter a department name')}
              name="departmentName"
              render={({ field, fieldState }) => {
                return <Textfield {...field} haserror={!!fieldState.error} label="Department name" type="text" responsive />;
              }}
            />

            {!!props.department && <TagInput onRemoveTag={onClickRemoveTagHandler} getAssignees={getTagAssignees} onCreateTag={onClickCreateTagHandler} initialTags={tags} onTagChange={onTagChange} searchTags={searchUserTags} />}
          </div>

          <footer className="dialogFooterLayout focusPanelFooterLayout" style={{ justifyContent: 'space-between' }}>
            <Anchor onClick={props.onClick}>Cancel</Anchor>
            <Button type="button">Submit</Button>
          </footer>
        </form>
      </div>
    </FocusPanelLoaderLayer>
  );
}

