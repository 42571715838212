import styled from 'styled-components';

export const FocusPanelLoaderLayerLoader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  width: 100%;
  height: 100vh;
  padding: 50px 50px 0px 50px;
  overflow: hidden;

  background: var(--background);

  pointer-events: all;
  opacity: 1;
  will-change: opacity;

  &.hidden {
    pointer-events: none;
    opacity: 0;
    transition: opacity 500ms;
    overflow-y: auto;
  }
`;

export const FocusPanelLoaderLayerContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 92px);
  margin: auto;
  overflow-y: auto;

  &.loading {
    overflow-y: hidden;
  }
`;
