import { Context } from '../..';
import {
  DailyActivityResponse,
  SkillScoresResponse,
  SkillScoresRequest,
  SkillScoreOverTimeResponse,
  LeaderboardResponse,
  LessonStatusCountsResponse,
  KeplerPointsResponse,
  PersonResponse,
  SkillLevelsResponse,
  ScoreTypesResponse,
  EntityScoresListResponse,
  RankedScoresResponse,
} from '../../../models/overmind/analytics';
import { EntityScoresSearchParams, FullAnalyticsSearchParams, LeaderboardSearchParams, PartialAnalyticsSearchParams, PeopleSearchParams } from '../../../models/overmind/search-params';
import { People } from '../../../models/view/people';
import * as base from '../base';

export async function analyticsGetDailyActivity(context: Context, props: PartialAnalyticsSearchParams): Promise<DailyActivityResponse | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `people`, `daily-activity${base.params(props)}`) };
  const response: base.IResponse<DailyActivityResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetSkillScores(context: Context, props: SkillScoresRequest): Promise<SkillScoresResponse[] | undefined> {
  const url = base.url(`analytics`, `skillscores`, `${props.responseGroup}${base.params(props.searchParams)}`);
  const request: base.IRequest = { url: url, authenticated: true };
  const response: base.IResponse<SkillScoresResponse[]> = await base.request(request);
  return response.data;
}

export async function analyticsGetSkillScoresOverTime(context: Context, props: FullAnalyticsSearchParams): Promise<SkillScoreOverTimeResponse[] | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `skill-levels`, `over-time${base.params(props)}`) };
  const response: base.IResponse<SkillScoreOverTimeResponse[]> = await base.request(request);
  return response.data;
}

export async function analyticsGetKeplerPointsLeaderboard(context: Context, props: LeaderboardSearchParams): Promise<LeaderboardResponse | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `keplerpoints`, `leaderboard${base.params(props)}`) };
  const response: base.IResponse<LeaderboardResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetSkillScoresLeaderboard(context: Context, props: LeaderboardSearchParams): Promise<LeaderboardResponse | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `skillscores`, `leaderboard${base.params(props)}`) };
  const response: base.IResponse<LeaderboardResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetLessonStatusCounts(context: Context, props: FullAnalyticsSearchParams): Promise<LessonStatusCountsResponse | undefined> {
  const url = base.url(`analytics`, `courses`, `status-counts${base.params(props)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<LessonStatusCountsResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetPersonCount(context: Context, props: FullAnalyticsSearchParams): Promise<number | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `skills`, `people`, `count${base.params(props)}`) };
  const response: base.IResponse<number> = await base.request(request);
  return response.data;
}

export async function analyticsGetKeplerPoints(context: Context, props: FullAnalyticsSearchParams): Promise<KeplerPointsResponse | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `keplerpoints`, `total${base.params(props)}`) };
  const response: base.IResponse<KeplerPointsResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetPerson(context: Context, props: string): Promise<PersonResponse | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `person`, props) };
  const response: base.IResponse<PersonResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetPeople(context: Context, props: PeopleSearchParams): Promise<People | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `people${base.params(props)}`) };
  const response: base.IResponse<People> = await base.request(request);
  return response.data;
}

export async function analyticsGetSkillLevels(context: Context, props: FullAnalyticsSearchParams): Promise<SkillLevelsResponse | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `skill-levels${base.params(props)}`) };
  const response: base.IResponse<SkillLevelsResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetEntityScoresList(context: Context, payload: EntityScoresSearchParams): Promise<EntityScoresListResponse | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `scores${base.params(payload)}`) };
  const response: base.IResponse<EntityScoresListResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetAvailableScoreTypes(context: Context, payload: EntityScoresSearchParams): Promise<ScoreTypesResponse | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `score-types${base.params(payload)}`) };
  const response: base.IResponse<ScoreTypesResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetRankedScores(context: Context, payload: EntityScoresSearchParams): Promise<RankedScoresResponse | undefined> {
  const request: base.IRequest = { url: base.url(`analytics`, `ranked${base.params(payload)}`) };
  const response: base.IResponse<RankedScoresResponse> = await base.request(request);
  return response.data;
}

