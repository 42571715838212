import styled from 'styled-components';

const DatepickerInputValue = styled.output`
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  font-size: 12px;
  color: var(--accent-2);
`;

export const DatepickerInner = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  flex: 1;
`;

const DatepickerInputLabel = styled.label`
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  font-size: 10px;
  color: var(--accent-3);
  font-weight: 500;
  font-family: var(--heading);
  cursor: pointer;
`;

const DatepickerInputWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: 45px;
  background: var(--cards);
  border: 1px solid var(--borders);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 5px 0 10px;
  cursor: pointer;
  flex: 0 0 auto;
  gap: 5px;
  flex: 1;

  &.hasError {
    border-color: var(--validation);
  }
`;

const DatepickerInputIcon = styled.div`
  display: grid;
  place-items: center;
  width: 22px;

  svg {
    display: block;
    width: 16px;
    height: auto;
    margin: auto;
  }
`;

// Dropdown
const DatepickerMobileBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--backdrop);
  z-index: 9998;
  display: none;

  @media screen and (max-width: 530px) {
    display: block;
  }
`;

const DatepickerDropdown = styled.div`
  position: absolute;
  width: 330px;
  top: 0;
  left: 50%;
  z-index: 9999;
  padding: 25px 15px;
  border: 1px solid var(--borders);
  background: var(--cards);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  transform: translateX(-50%);
  animation: DropdownIn forwards 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 45px;

  @keyframes DropdownIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.fixed {
    position: fixed;
    width: 100%;
    max-width: 330px;
  }

  &.autoHeight {
    min-height: unset;
  }

  &.openAbove {
    top: auto;
    bottom: 0;
  }

  @media screen and (max-width: 530px) {
    &:not(.fixed) {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const DatepickerDropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 15px 0;
  user-select: none;

  div.label {
    color: var(--text);
  }

  span.control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;

    svg {
      display: block;
      height: 100%;
      width: auto;
    }

    &:last-child svg {
      transform: rotate(180deg);
    }

    &:hover svg path {
      stroke: var(--primary_2);
    }

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;

      svg path {
        stroke: var(--text_2);
      }
    }
  }
`;

const DatepickerDropdownLabels = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 5px 0;
  margin: 0;

  li {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: var(--accent-3);
  }
`;

const DropdownDateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;

  &:last-child {
    padding-bottom: 0;
  }
`;

const DropdownDateDay = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--text);
  border-radius: 50%;
  cursor: pointer;
  border: solid 1px transparent;

  &:hover {
    border-color: var(--primary_2);
  }

  &.overflow {
    color: var(--text_2);
  }

  &.today {
    border-color: transparent;
    background: var(--primary_2);
  }

  &.selected {
    border-color: transparent;
    background: var(--primary_1);
    color: var(--contrast-text);
  }

  &.disabled {
    cursor: not-allowed;
    color: var(--background_2);
    border-color: transparent;
    opacity: 0.4;
    user-select: none;
  }
`;

const DatepickerDropdownBody = styled.div`
  display: block;
  margin-bottom: auto;
`;

const DatepickerDropdownFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0 10px 0;

  button {
    appearance: none;
    display: block;
    border-radius: 30px;
    line-height: 30px;
    padding: 4px 14px;
    border: none;
    background: var(--cards);
    border: solid 1px var(--primary);
    font-family: var(--body);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: var(--text);
    text-transform: uppercase;
    cursor: pointer;
  }
`;

export { DatepickerMobileBackground, DatepickerInputWrapper, DatepickerInputLabel, DatepickerInputIcon, DatepickerDropdown, DatepickerDropdownBody, DatepickerDropdownHeader, DropdownDateRow, DropdownDateDay, DatepickerDropdownLabels, DatepickerInputValue, DatepickerDropdownFooter };
