import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { UniqueEntity } from '../../../models';
import { OrganizationLevelType } from '../../../enums';
import { extractHighestOrganizationLevel } from '../../../lib/permissions.helpers';
import { Mode } from '../../../navigation/navigation.enums';
import { IPermissionsEntityDropdownWidgetProps, PermissionsEntity } from './permissions-entity-dropdown.models';
import { DropdownSelect, DropdownSelectItem } from '@keplerco/core';

export function PermissionsEntityDropdownWidget(props: IPermissionsEntityDropdownWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { mode, permissions, companyVariables } = useAppState();

  const [menuItems, setMenuItems] = useState<DropdownSelectItem[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<string>('');

  async function generateEntities(companySlug: string | undefined): Promise<UniqueEntity[] | undefined> {
    if (!companySlug) return undefined;
    const departments = await actions.getAnalyticsDepartments(companySlug);
    return departments?.map(department => ({
      name: department.name,
      slug: department.slug,
    }));
  }

  function generateMenuItems(entities: UniqueEntity[], organizationLevel: OrganizationLevelType, slug: string | undefined) {
    const allEntity: PermissionsEntity = {
      organizationLevel: !!slug ? OrganizationLevelType.Company : organizationLevel,
      entity: { name: 'All', slug },
    };

    const items: DropdownSelectItem[] = entities.map(entity => ({
      label: entity.name,
      value: entity.name,
      onClick: () => handleEntitySelect({ organizationLevel, entity }),
      selected: selectedEntity === entity.name,
    }));

    const allItem: DropdownSelectItem = {
      label: 'All',
      value: 'All',
      onClick: () => handleEntitySelect(allEntity),
      selected: selectedEntity === 'All',
    };

    setMenuItems([allItem, ...items]);

    if (!selectedEntity) {
      setSelectedEntity('All');
      props.onClick(allEntity);
    }
  }

  function handleEntitySelect(entity: PermissionsEntity) {
    setSelectedEntity(entity.entity.name);
    props.onClick(entity);
  }

  useEffect(() => {
    async function getData() {
      // role based
      if (mode === Mode.PlatformManagement) {
        const entities = await generateEntities(companyVariables.slug);
        if (!entities) return;

        generateMenuItems(entities, OrganizationLevelType.Department, companyVariables.slug);
        return;
      }

      // permissions based
      if (!permissions) return;
      const organizationLevel = extractHighestOrganizationLevel(permissions?.analytics.organizationLevels);
      if (!organizationLevel) return;

      // company
      if (organizationLevel.organizationLevel === OrganizationLevelType.Company) {
        const entities = await generateEntities(organizationLevel.entities[0].slug);
        if (!entities) return;

        generateMenuItems(entities, OrganizationLevelType.Department, organizationLevel?.entities[0].slug);
        return;
      }

      // department
      if (organizationLevel.organizationLevel === OrganizationLevelType.Department) {
        generateMenuItems(organizationLevel.entities, OrganizationLevelType.Department, undefined);
        return;
      }

      // team
      if (organizationLevel.organizationLevel === OrganizationLevelType.Team) {
        generateMenuItems(organizationLevel.entities, OrganizationLevelType.Team, undefined);
        return;
      }
    }

    getData();
  }, [mode, companyVariables.slug, permissions]);


  return (
    <React.Fragment>
      {!!menuItems.length && (
        <DropdownSelect
          label="Filter By"
          value={selectedEntity}
          items={menuItems}
        />
      )}
    </React.Fragment>
  );
}
