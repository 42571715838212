import { Context } from '../..';
import { ExternalLoginModel } from '../../../models/external-login-model';
import { ForgotPassword } from '../../../models/view/forgot-password';
import { getSubdomain } from '../../../lib/get-subdomain';
import { getTotalLearnerKeplerPoints } from './kepler-points';
import { handleErrorToErrorPage } from '../functions';
import { initialiseAuthenticatedState } from './util';
import { LoginInput } from '../../../models/view/login-input';
import { pipe } from 'overmind';
import { ResetPassword } from '../../../models/view/reset-password';
import { AuthenticationStatus, ErrorSignalTypes, SetPasswordStatus } from '../../../enums';
import { syncCourseContent } from './data-sync';
import { LoginResult, UserRegistration } from '../../../models/user';
import * as base from '../base';
import { track } from '../../../lib/track';
import { QueueItemPriority, QueueItemType } from '../../../components';
import { PagePath } from '../../../navigation/navigation.enums';
import { DeveloperConsole, deleteCookie } from '@keplerco/core';

const dconsole = new DeveloperConsole('User', ['#E5145F', '#ffffff']);

// auth
export async function forgotPassword(context: Context, forgotPasswordModel: ForgotPassword): Promise<boolean> {
  const request: base.IRequest = { url: base.url(`user`, `forgot-password`), authenticated: true, method: 'POST', body: JSON.stringify(forgotPasswordModel) };
  const response: base.IResponse<any> = await base.request(request);

  if (response.error) {
    context.state.setPasswordStatus = SetPasswordStatus.Failed;
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);
    return false;
  }

  return true;
}

export async function logout(): Promise<void> {
  deleteCookie('kepler.cookie');

  const request: base.IRequest = { url: base.url(`user`, `logout`), authenticated: true, method: 'POST' };
  await base.request(request);

  window.sessionStorage.clear();

  window.location.href = '/account/login';
  window.location.reload();
}

// Pass the user's credentials to the api to verify and authenticate them and the return and set a user token as a cookie. Set's the user's authentication status based on the validity of their credentials
async function verifyUserCredentials(context: Context, loginInputModel: LoginInput): Promise<void> {
  context.state.authenticationStatus = AuthenticationStatus.UnAuthenticated;

  const request: base.IRequest = { url: base.url(`user`, `login`), authenticated: true, method: 'POST', body: JSON.stringify(loginInputModel) };
  const response: base.IResponse<any> = await base.request(request);

  context.state.authenticationStatus = response.error ? AuthenticationStatus.AuthenticationFailed : AuthenticationStatus.Authenticated;
}

export function getLoginResult(context: Context): LoginResult {
  if (!!context.state.user)
    track('KPLR-USER-LOGIN', {
      userId: context.state.user.learnerSlug,
      companyId: context.state.user.companySlug,
      status: context.state.user.completedOnboarding ? 'ONBOARDING COMPLETE' : 'ONBOARDING INCOMPLETE',
    });

  return {
    authenticationStatus: context.state.authenticationStatus,
    hasCompletedOnboarding: context.state.user?.completedOnboarding ?? false,
    isSystemAdmin: context.state.user?.isSystemAdmin ?? false,
  };
}

export async function externalLogin(_: Context, externalLoginModel: ExternalLoginModel): Promise<void> {
  const form = document.createElement('form');

  form.setAttribute('method', 'POST');
  form.setAttribute('action', base.url(`user`, `external-login?provider=${externalLoginModel.provider}&kt=${externalLoginModel.kt}&code=${externalLoginModel.code}&returnUrl=${externalLoginModel.returnUrl}`));

  document.body.appendChild(form);
  form.submit();
}

export const login = pipe(verifyUserCredentials, initialiseAuthenticatedState, syncCourseContent, getTotalLearnerKeplerPoints, getLoginResult);

function mapErrorMessage(message: string) {
  if (message === 'Invalid token.') {
    return `The email address you have entered does not match the address we sent the activation email to`;
  }

  if (message === 'Please check your email for typos') {
    return `You seem to have entered your email address incorrectly. Please enter the address that your activation email was sent to`;
  }

  return message;
}

export async function setPassword(context: Context, resetPasswordModel: ResetPassword): Promise<void> {
  const request: base.IRequest = { url: base.url(`user`, `reset-password`), authenticated: true, method: 'POST', body: JSON.stringify(resetPasswordModel) };
  const response: base.IResponse<any> = await base.request(request);

  if (response.error) {
    context.state.setPasswordStatus = SetPasswordStatus.Failed;
    context.actions.addNotification({
      id: Date.now().toString(),
      title: response.error.title,
      message: mapErrorMessage(response.error.detail ?? ''),
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      active: true,
    });

    return;
  }

  if (response.data.errors !== undefined && response.data.errors.length > 0) {
    context.state.passwordErrors = response.data.errors;
    context.state.setPasswordStatus = SetPasswordStatus.Failed;
  } else {
    context.state.setPasswordStatus = SetPasswordStatus.Succeeded;
    context.state.authenticationStatus = AuthenticationStatus.Authenticated;
  }
}

export const setPasswordAndSync = pipe(
  ({ state }: Context, resetPasswordModel: ResetPassword) => {
    state.authenticationStatus = AuthenticationStatus.UnAuthenticated;
    return resetPasswordModel;
  },
  setPassword,
  initialiseAuthenticatedState,
  syncCourseContent,
  getLoginResult
);

export async function requestAccountActivation(context: Context, forgotPasswordModel: ForgotPassword): Promise<void> {
  const request: base.IRequest = { url: base.url(`user`, `request-activation`), authenticated: true, method: 'POST', body: JSON.stringify(forgotPasswordModel) };
  const response: base.IResponse<any> = await base.request(request);

  if (response.error) {
    context.state.setPasswordStatus = SetPasswordStatus.Failed;
    dconsole.error({ title: 'Whoops, Something Went Wrong', description: 'There was a error activating your account, please try again later.' });
    return;
  }

  context.state.setPasswordStatus = SetPasswordStatus.Succeeded;
}

export async function registerUser(context: Context, registration: UserRegistration): Promise<{ message: string } | undefined> {
  const request: base.IRequest = { url: base.url(`user`, `register`, getSubdomain()), authenticated: true, method: 'post', body: JSON.stringify(registration) };
  const response: base.IResponse<{ message: string }> = await base.request(request);

  console.log(response.error?.type);

  if (response.error?.type.includes('The provided email is already in use.')) {
    context.actions.addNotification({
      id: crypto.randomUUID(),
      toastHTML: {
        __html: `Having trouble signing in? If you've already activated your account, <a href="${window.location.origin}${PagePath.accountBase}${PagePath.accountLogin}">sign in</a>. Or you can <a href="${window.location.origin}${PagePath.accountBase}${PagePath.accountForgotPassword}">reset your password</a>.`,
      },
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      active: true,
    });
  }

  if (response.error?.type.includes('Passwords')) {
    const items = response.error.type.split('.').map(i => i.trim());
    context.state.passwordErrors = items.filter(i => !!i);
  }

  return response.data;
}

export function clearPasswordErrors(context: Context) {
  context.state.passwordErrors = void 0;
}

export async function sendActivationReminders(context: Context, requestModel: { companySlug: string; emails: string[] }) {
  const request: base.IRequest = { url: base.url(`user`, `send-activation-reminders`, requestModel.companySlug), authenticated: true, method: 'POST', body: JSON.stringify(requestModel.emails) };

  const response: base.IResponse<any> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'Error Sending Activation Reminder(s)',
      message: `There was an issue sending the reminders. Please try again.`,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      id: `activation_reminders_error_${Date.now()}`,
      active: true,
    });
    return;
  }

  context.actions.addNotification({
    title: 'Activation Reminder(s) Sending',
    message: `This may take a while, feel free to keep working while we finish up.`,
    priority: QueueItemPriority.Toast,
    type: QueueItemType.Success,
    id: `activation_reminders_sending_${Date.now()}`,
    active: true,
  });
}

export async function sendActivationInvitations(context: Context, requestModel: { companySlug: string; emails: string[] }) {
  const request: base.IRequest = {
    url: base.url('user', 'send-activation-invitations', requestModel.companySlug),
    authenticated: true,
    method: 'POST',
    body: JSON.stringify(requestModel.emails),
  };

  const response: base.IResponse<any> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'Error Sending Activation Invitation(s)',
      message: `There was an issue sending the invitations. Please try again.`,
      priority: QueueItemPriority.Toast,
      type: QueueItemType.Error,
      id: `activation_invitations_error_${Date.now()}`,
      active: true,
    });
    return;
  }

  context.actions.addNotification({
    title: 'Activation Invitation(s) Sending',
    message: `This may take a while, feel free to keep working while we finish up.`,
    priority: QueueItemPriority.Toast,
    type: QueueItemType.Success,
    id: `activation_invitations_sending_${Date.now()}`,
    active: true,
  });
}
