import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { IEmptyStateProps } from '../../../components/empty-state/empty-state.models';
import { SortField } from '../../../enums/sort-field';
import { FetchStatus, FetchType } from '../../../enums';
import { DepartmentListItem } from '../../../models/view/department-list-item';
import { useAppActions, useAppState } from '../../../overmind';
import { DepartmentsSearchRequest } from '../../../models/overmind/search-request';
import { ManageDepartmentFocusPanelCMSLayout } from './manage-department-focus-panel.cms.layout';
import * as s from './departments.cms.state';
import { DepartmentsCMSSkeleton } from './departments.cms.skeleton';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { ColumnConfiguratorWidget, ConfirmationModalLayout } from '../../../widgets/layouts';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { PagerNavigationConnector } from '../../../components/pager-connector/pager-connector';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { CMSColumn, CMSRow } from '../../../theme/layout.styles';
import { AddTwoToneIcon, Anchor, Button, FocusPanel, KebabMenu, MenuItem, Modal, PageHeader, Pager, Searchfield, Table, TableColumn, TableRow } from '@keplerco/core';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';

function createDepartmentLink(companySlug: string, departmentId: number | string) {
  const slugged = PagePath.administrationDepartmentPeople.replace(':companySlug', companySlug);
  return `${PagePath.administrationBase}${slugged.replace(':departmentSlug', departmentId?.toString())}`;
}

export function DepartmentsCMSPage(): JSX.Element {
  const params = useParams<any>();
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { user, fetchState } = useAppState();

  const [state, dispatch] = useReducer(s.departmentsReducer, s.initialState);

  async function getData() {
    const request: DepartmentsSearchRequest = { ...state.request, pageSize: state.pageSize };

    const tempData = await actions.searchDepartments(request);

    let additionalData: any = {};

    if (!!params.departmentSlug) {
      const department = await actions.getDepartment({ companySlug: params.companySlug!, departmentSlug: params.departmentSlug! });

      additionalData = {
        entityName: department?.name,
        departmentSlug: params.departmentSlug,
      };
    } else if (!!params.teamSlug) {
      const team = await actions.getTeam({ companySlug: params.companySlug!, teamSlug: params.teamSlug! });

      additionalData = {
        entityName: team?.teamName,
        teamSlug: params.teamSlug!,
      };
    } else {
      if (user?.isSystemAdmin) {
        const company = await actions.getCompany(params.companySlug!);

        additionalData = {
          entityName: company?.companyName,
        };
      } else
        additionalData = {
          entityName: user?.companyName,
        };
    }

    if (!tempData) return;

    dispatch({ type: s.DepartmentsActionTypes.PopulateData, payload: { data: tempData, ...additionalData } });
  }

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: PagePath.administrationDepartments, type: !!state.departments ? FetchType.Custom : FetchType.PageFetching });

      await getData();

      actions.stopLoader(PagePath.administrationDepartments);
    }

    if (!state.request || state.request.departmentSlug !== params.departmentSlug || state.request.teamSlug !== params.teamSlug || state.request.companySlug !== params.companySlug) {
      const request: DepartmentsSearchRequest = {};

      request.companySlug = params.companySlug;
      if (!!params.teamSlug) request.teamSlug = params.teamSlug;
      if (!!params.departmentSlug) request.departmentSlug = params.departmentSlug;

      dispatch({
        type: s.DepartmentsActionTypes.SetRequest,
        payload: {
          ...request,
          sortAscending: true,
          sortField: SortField.Name,
        },
      });
    } else initPage();
  }, [state.request, params.departmentSlug, params.teamSlug, params.companySlug]);

  const emptyState: IEmptyStateProps = {
    title: 'No departments found',
    buttons: [{ text: 'Create a department', onClick: () => dispatch({ type: s.DepartmentsActionTypes.SetDepartmentToManage, payload: { show: true, reset: false } }) }],
  };

  async function archiveDepartment(departmentSlug: string) {
    actions.startLoader({ path: PagePath.administrationDepartments, type: FetchType.Sending });
    await actions.archiveDepartment(departmentSlug);
    closeConfirmationModal();
    actions.stopLoader(PagePath.administrationDepartments);
  }

  function getKebabMenuItems(department: DepartmentListItem): MenuItem[] {
    const kebabMenuItems: MenuItem[] = [
      {
        label: 'Manage people',
        onClick: () => keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationDepartmentPeople.replace(':companySlug', params.companySlug!).replace(':departmentSlug', department.slug)}`),
      },
      {
        label: 'Manage teams',
        onClick: () => keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationDepartmentTeams.replace(':companySlug', params.companySlug!).replace(':departmentSlug', department.slug)}`),
      },
      {
        label: 'Manage department',
        onClick: () => onClickDepartmentHandler(department),
      },
    ];

    if (department.totalUsers === 0) {
      kebabMenuItems.push({
        label: 'Archive',
        onClick: () => dispatch({ type: s.DepartmentsActionTypes.SetArchiveDepartment, payload: department }),
      });
    }

    return kebabMenuItems;
  }

  async function onInputHandler(value: string) {
    dispatch({ type: s.DepartmentsActionTypes.UpdateRequest, payload: { ...state.request, search: value, page: 1 } });
  }

  function onClickDepartmentHandler(department: DepartmentListItem) {
    dispatch({ type: s.DepartmentsActionTypes.SetDepartmentToManage, payload: { show: true, reset: false, data: department } });
  }

  function onClickManageDepartmentHandler() {
    dispatch({ type: s.DepartmentsActionTypes.SetDepartmentToManage, payload: { data: void 0, show: false, reset: true } });
  }

  function onClickCancelManagingColumns() {
    dispatch({ type: s.DepartmentsActionTypes.ManageColumns, payload: { open: false, reset: false } });
  }

  function onClickResetManagingColumns() {
    dispatch({ type: s.DepartmentsActionTypes.ManageColumns, payload: { open: true, reset: true } });
  }

  function onClickSubmitManagingColumns(selection: ColumnConfiguration[]) {
    dispatch({ type: s.DepartmentsActionTypes.EditColumnConfiguration, payload: selection });
    dispatch({ type: s.DepartmentsActionTypes.ManageColumns, payload: { open: false } });
  }

  const onSortHandler = async (column: any) => {
    const isCurrentlyAscending = state.request?.sortAscending;
    const currentSortField = state.request?.sortField;

    const newSortDirection = currentSortField === column ? !isCurrentlyAscending : true;

    dispatch({
      type: s.DepartmentsActionTypes.SetRequest,
      payload: {
        ...state.request,
        sortField: column,
        sortAscending: newSortDirection,
      },
    });
  };

  function closeConfirmationModal() {
    getData();
    dispatch({ type: s.DepartmentsActionTypes.SetArchiveDepartment, payload: void 0 });
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.administrationDepartments} skeletonLoader={<DepartmentsCMSSkeleton />}>
        <div className="wrapper stack" style={{ justifyContent: 'start' }}>
          <CMSRow>
            <CMSColumn>
              <PageHeader breadcrumbs={state.crumbs} title={state.pageTitle} />
            </CMSColumn>
          </CMSRow>

          <CMSRow>
            <CMSColumn>
              <Searchfield loading={fetchState[PagePath.administrationDepartments].status === FetchStatus.Active && fetchState[PagePath.administrationDepartments].type === FetchType.Custom} onInput={onInputHandler} />
            </CMSColumn>

            <CMSColumn style={{ marginLeft: 'auto' }}>
              <Button type="button" square theme="dark" onClick={() => dispatch({ type: s.DepartmentsActionTypes.SetDepartmentToManage, payload: { show: true, reset: false } })}>
                <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                  <AddTwoToneIcon />
                  Create a department
                </div>
              </Button>
            </CMSColumn>
          </CMSRow>

          <CMSRow>
            <CMSColumn style={{ width: '100%' }}>
              <Table
                currentSortBy={String(state.request?.sortField)}
                onSort={onSortHandler}
                currentSortDirection={state.request?.sortAscending ? 'Ascending' : 'Descending'}
                onConfigClicked={() => dispatch({ type: s.DepartmentsActionTypes.ManageColumns, payload: { open: true } })}
                emptyState={<EmptyState {...emptyState} />}
                footerContent={
                  <PagerNavigationConnector
                    {...state.paging}
                    onPageChange={page => {
                      if (page !== state.request?.page) {
                        dispatch({
                          type: s.DepartmentsActionTypes.SetRequest,
                          payload: {
                            ...state.request,
                            page,
                          },
                        });
                      }
                    }}
                    defaultPageNumber={state.request?.page ?? 1}
                    pageCount={state.departments?.totalPages ?? 1}
                  >
                    {connector => {
                      return <Pager {...connector} />;
                    }}
                  </PagerNavigationConnector>
                }
              >
                {state.departments?.departments.map(department => (
                  <TableRow id={department.slug} key={department.slug} configCell={() => <KebabMenu items={getKebabMenuItems(department)} />}>
                    <TableColumn sortable id="department" label="Department">
                      {!!department.slug && (
                        <Anchor
                          hovertype="opacity"
                          textTransform="none"
                          onClick={event => {
                            event.stopPropagation();
                            keplerNavigate(createDepartmentLink(params.companySlug!, department.slug));
                          }}
                        >
                          {department.name}
                        </Anchor>
                      )}
                      {!department.slug && <>{department.name}</>}
                    </TableColumn>

                    <TableColumn id="teams" label="Teams" hidden={!state.columnConfiguration.find(i => i.key === 'teams')?.selected}>
                      {department.totalTeams}
                    </TableColumn>

                    <TableColumn hidden={!state.columnConfiguration.find(i => i.key === 'dateCreated')?.selected} id="dateCreated" label="Date added">
                      {new Date(department.dateCreated).toLocaleString('en-UK', { dateStyle: 'long' })}
                    </TableColumn>

                    <TableColumn id="people" label="People" hidden={!state.columnConfiguration.find(i => i.key === 'people')?.selected}>
                      {department.totalUsers}
                    </TableColumn>
                  </TableRow>
                ))}
              </Table>
            </CMSColumn>
          </CMSRow>
        </div>
      </PageLoaderLayer>

      <FocusPanel
        open={state.departmentToManage?.show}
        onClose={() => {
          dispatch({ type: s.DepartmentsActionTypes.SetDepartmentToManage, payload: { show: false, reset: false, data: void 0 } });
        }}
      >
        {state.departmentToManage?.show && <ManageDepartmentFocusPanelCMSLayout department={state.departmentToManage?.data} onClick={onClickManageDepartmentHandler} />}
      </FocusPanel>

      <FocusPanel open={state.managingColumns} onClose={onClickCancelManagingColumns}>
        <ColumnConfiguratorWidget onReset={onClickResetManagingColumns} onCancel={onClickCancelManagingColumns} onSave={onClickSubmitManagingColumns} columns={state.columnConfiguration} />
      </FocusPanel>

      <Modal open={!!state.archiveDepartment} onClose={closeConfirmationModal}>
        {!!state.archiveDepartment && (
          <ConfirmationModalLayout
            title="Are you sure you want to archive the following department?"
            titleSize="small"
            subtitle={state.archiveDepartment.name}
            subtitleSize="large"
            submitButtonText="Yes, archive"
            onClickSubmit={() => {
              if (!!state.archiveDepartment) archiveDepartment(state.archiveDepartment.slug);
            }}
            cancelButtonText="No, cancel"
            onClickCancel={closeConfirmationModal}
          />
        )}
      </Modal>
    </React.Fragment>
  );
}


