export enum SkillScoreComparison {
  None = 'None',
  Company = 'Company',
  Department = 'Department',
  Team = 'Team',
  Role = 'Role',
  FoPExpectedProficiency = 'FoPExpectedProficiency',
  RoleToSkillExpectedProficiency = 'RoleToSkillExpectedProficiency',
  // FieldOfPractice = 'FieldOfPractice' TODO - https://keplerandco.atlassian.net/browse/KPRDEV-2136
}
