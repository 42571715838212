import React, { useEffect, useReducer } from 'react';
import { additionalLearningTypeIcons } from '../additional-learning/additional-learning.models';
import { FetchStatus, FetchType, SyncStatus } from '../../../enums';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { AppPageHeader as LocalPageHeader } from '../../../theme/layout.styles';
import { ReflectionCardListItemBodyLayout, ReflectionCardListItemImage } from './reflections.styles';
import { ReflectionFocusPanelLayout } from './reflection-focus-panel.layout';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { ReflectionsSkeleton } from './reflections.skeleton';
import * as store from './reflections.store';
import { useDataSyncReaction } from '../../../lib/data-sync.hooks';
import { SyncContentCard, SyncContentCardLayoutWrapper } from '../../../components';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/loading-handling/loader-layers/page-loader-layer/page-loader-layer';
import { Anchor, ListItemLayout, ListLayout, PageHeader, Panel, Searchfield, colourString, useMatchScreenWidth } from '@keplerco/core';

export function ReflectionsPage(): JSX.Element {
  const navigate = useNavigate();

  const { fetchState, lastSynced, courseSyncStatus } = useAppState();
  const actions = useAppActions();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const isMobile = useMatchScreenWidth('mobile');

  async function getData(useLoader: boolean = true) {
    if (useLoader) actions.startLoader({ path: PagePath.learningJourneyCourseReflections, type: FetchType.PageFetching });

    const response = await actions.searchReflectionList(state.searchTerm);
    dispatch({ type: store.ReflectionsPageActionTypes.SetReflections, payload: response });

    if (useLoader) actions.stopLoader(PagePath.learningJourneyCourseReflections);
  }

  useDataSyncReaction(async () => {
    getData(false);
  });

  useEffect(() => {
    getData();
  }, []);

  async function onInputHandler(value: string) {
    actions.startLoader({ path: PagePath.learningJourneyCourseReflections, type: FetchType.Custom });

    dispatch({ type: store.ReflectionsPageActionTypes.SetSearchTerm, payload: value });
    const response = await actions.searchReflectionList(value);
    dispatch({ type: store.ReflectionsPageActionTypes.SetReflections, payload: response });

    actions.stopLoader(PagePath.learningJourneyCourseReflections);
  }

  async function onClickHandler(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, reflectionId: number) {
    event.stopPropagation();
    dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: reflectionId });
  }

  return (
    <>
      <PageLoaderLayer path={PagePath.learningJourneyCourseReflections} skeletonLoader={<ReflectionsSkeleton />}>
        <div className="pageWrapper wrapper">
          <LocalPageHeader>
            <PageHeader
              breadcrumbs={[
                {
                  name: 'Learning Journey',
                  url: PagePath.learningJourneyBase,
                },
                {
                  name: 'Reflections',
                  url: `${PagePath.learningJourneyBase}${PagePath.learningJourneyCourseReflections}`,
                },
              ]}
              title="Reflections"
              subtitle="Reflection is an essential element of learning in your continuous professional development. Reflecting enables you to build on your prior learning, as well as validate or challenge your existing skills and knowledge."
            />

            <SyncContentCardLayoutWrapper>
              <SyncContentCard
                isSyncing={courseSyncStatus === SyncStatus.Syncing}
                variant="large"
                lastSyncDate={lastSynced}
                onClick={() => {
                  actions.syncCourseContent().then(() => {
                    actions.getTotalLearnerKeplerPoints();
                  });
                }}
              />
            </SyncContentCardLayoutWrapper>
          </LocalPageHeader>

          <div className="row">
            <div className="column">
              <Searchfield label="Search by skill" loading={fetchState[PagePath.learningJourneyCourseReflections].status === FetchStatus.Active && fetchState[PagePath.learningJourneyCourseReflections].type === FetchType.Custom} onInput={onInputHandler} />
            </div>
          </div>

          {!state.reflections?.length ? (
            <div style={{ display: 'grid', alignItems: 'center', padding: '60px 0' }}>
              <EmptyState badgeIconWidth={400} title="No reflections found" subtitle="You need to complete some courses before you can reflect on them" buttons={[{ text: 'View Learning Journey', onClick: () => navigate(PagePath.learningJourneyBase) }]} />
            </div>
          ) : (
            <React.Fragment>
              {!!state.pendingReflections && state.pendingReflections.length > 0 && (
                <React.Fragment>
                  <h4 className="heading4" style={{ marginTop: 30, marginBottom: 10 }}>
                    Pending
                  </h4>

                  <ListLayout>
                    {state.pendingReflections.map(reflection => {
                      const imageUrl = reflection.lessonImage || additionalLearningTypeIcons.find(item => item.type === reflection.offPlatformLearningType)?.listUrl;

                      return (
                        <ListItemLayout key={reflection.reflectionId} onClick={() => dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: reflection.reflectionId })}>
                          <div className="card hasStrip">
                            <div className="strip" style={{ background: colourString('secondary') }} />

                            {!isMobile && (
                              <ReflectionCardListItemImage>
                                <img src={imageUrl} alt="" draggable="false" />
                              </ReflectionCardListItemImage>
                            )}

                            <div className="cardListItemContentLayout" style={{ paddingLeft: isMobile ? 0 : 'max(20%, 200px)' }}>
                              <ReflectionCardListItemBodyLayout className="cardListItemBodyLayout">
                                {!!reflection.skillName && <p className="body2">{reflection.skillName}</p>}
                                <h5 className="heading5">{reflection.lessonName}</h5>
                              </ReflectionCardListItemBodyLayout>

                              <footer className="cardListItemFooterLayout">
                                <Anchor style={{ whiteSpace: 'nowrap' }} block variant="tiny" onClick={event => onClickHandler(event, reflection.reflectionId)}>
                                  Capture reflection
                                </Anchor>
                              </footer>
                            </div>
                          </div>
                        </ListItemLayout>
                      );
                    })}
                  </ListLayout>
                </React.Fragment>
              )}

              {!!state.completeReflections && state.completeReflections.length > 0 && (
                <React.Fragment>
                  <h4 className="heading4" style={{ marginTop: 30, marginBottom: 10 }}>
                    Complete
                  </h4>

                  <ListLayout>
                    {state.completeReflections.map(reflection => {
                      const imageUrl = reflection.lessonImage || additionalLearningTypeIcons.find(item => item.type === reflection.offPlatformLearningType)?.listUrl;

                      return (
                        <ListItemLayout key={reflection.reflectionId} onClick={() => dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: reflection.reflectionId })}>
                          <div className="card hasStrip">
                            <div className="strip" style={{ background: colourString('secondary') }} />
                            {!isMobile && (
                              <ReflectionCardListItemImage>
                                <img src={imageUrl} alt="" draggable="false" />
                              </ReflectionCardListItemImage>
                            )}

                            <div className="cardListItemContentLayout" style={{ paddingLeft: isMobile ? 0 : 'max(200px, 20%)' }}>
                              <ReflectionCardListItemBodyLayout className="cardListItemBodyLayout">
                                {!!reflection.skillName && <p className="body2">{reflection.skillName}</p>}
                                <h5 className="heading5">{reflection.lessonName}</h5>
                              </ReflectionCardListItemBodyLayout>

                              <footer className="cardListItemFooterLayout">
                                <span className="caption1">Completed: {new Date(reflection.dateCompleted).toLocaleString('en-UK', { dateStyle: 'long' })}</span>
                              </footer>
                            </div>
                          </div>
                        </ListItemLayout>
                      );
                    })}
                  </ListLayout>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </PageLoaderLayer>

      <Panel open={!!state.selectedReflectionId} onClose={() => dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: undefined })}>
        {!!state.selectedReflectionId && (
          <ReflectionFocusPanelLayout
            reflectionId={state.selectedReflectionId}
            path={PagePath.learningJourneyCourseReflections}
            onCancel={() => dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: undefined })}
            onSubmit={() => {
              getData();
              dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: undefined });
            }}
          />
        )}
      </Panel>
    </>
  );
}

