import React from 'react';
import { QueueItem, QueueItemType } from '../notification-hub.models';
import { KeplerPointType } from '../../../enums';
import * as layouts from '../../../widgets/layouts';
import { Button } from '@keplerco/core';

export function ModalLayoutContainer(props: QueueItem & { onAction: () => void }): JSX.Element {
  if (props.keplerPointType === KeplerPointType.CategoryCompleted) {
    return <layouts.CategoryCompletedModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.SkillsGapReport) {
    return <layouts.SkillsGapReportModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.JourneyCompleted) {
    return <layouts.JourneyCompletedModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.LoginStreak) {
    return <layouts.LoginStreakModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.LevelAchieved) {
    return <layouts.LevelAchievedModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.SkillScorePostAssessmentUpdated) {
    return <layouts.SkillScorePostAssessmentUpdatedModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.VariationQuestionReady) {
    return <layouts.VariationQuestionReadyModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.SkillScorePreAssessmentUpdated) {
    return <layouts.SkillScorePreAssessmentUpdatedModalLayout {...props} />;
  }

  if (props.type === QueueItemType.MissionBriefing) {
    return <layouts.MissionBriefingModalLayout {...props} />;
  }

  return (
    <div className="modalContent">
      <header className="dialogHeaderLayout modalHeaderLayout">
        <h2 className="heading2">{props.title}</h2>
      </header>

      <section className="dialogBodyLayout">
        <h6 className="subtitle">{props.message}</h6>
      </section>

      <footer className="dialogFooterLayout modalFooterLayout">
        <Button type="button" filled onClick={props.onAction}>
          Okay, Got it.
        </Button>
      </footer>
    </div>
  );
}
