import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAppActions, useAppState } from '../../../overmind';
import { OrganizationLevelType } from '../../../enums';
import { FullAnalyticsSearchParams } from '../../../models/overmind/search-params';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { SkeletonLoader } from '../../../components';
import { SkillLevelOverTimeWidgetLegendElement, SkillLevelOverTimeWidgetLegend } from './skill-scores-over-time.styles';
import { ISkillLevelOverTimeWidgetProps, ValueType } from './skill-scores-over-time.models';
import { lineChartIncrements } from '../../../lib/line-chart-increments';
import { ILineChartDataPoint, LineChart, ThemeColours } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';

const colorMap: Map<ValueType, ThemeColours> = new Map<ValueType, ThemeColours>([
  [ValueType.RoleBased, 'baby-blue'],
  [ValueType.Behavioural, 'blue'],
  [ValueType.Overall, 'text'],
]);

export function SkillScoreOverTimeWidget({ entity, path }: ISkillLevelOverTimeWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { dateRange, companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [shownLines, setShownLines] = useState<ValueType[]>([ValueType.RoleBased, ValueType.Behavioural, ValueType.Overall]);
  const [increments, setIncrements] = useState<number>(20);
  const [data, setData] = useState<ILineChartDataPoint[]>([]);

  useEffect(() => {
    async function getData() {
      if (!entity) return;

      setLoading(true);

      const searchParams: FullAnalyticsSearchParams = {
        startDate: path === PagePath.dashboard ? undefined : dateRange?.from?.toJSON(),
        endDate: path === PagePath.dashboard ? undefined : dateRange?.to?.toJSON(),
        organizationLevel: entity.organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
      };

      if (entity.organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug = entity.entity.slug;
      const response = await actions.analyticsGetSkillScoresOverTime(searchParams);

      if (!response?.length) {
        setData([]);
        setLoading(false);
        return;
      }

      const tempData: ILineChartDataPoint[] = response.map(entry => ({
        label: new Date(entry.dateTime).toLocaleString('en-UK', { dateStyle: 'long' }),
        value: [
          {
            name: ValueType.RoleBased,
            value: companyVariables.useLevels ? (entry.technicalScore?.level || 0) : (entry.technicalScore?.percentage || 0),
            dataType: companyVariables.useLevels ? 'none' : 'percentage'
          },
          {
            name: ValueType.Behavioural,
            value: companyVariables.useLevels ? (entry.behaviouralScore?.level || 0) : (entry.behaviouralScore?.percentage || 0),
            dataType: companyVariables.useLevels ? 'none' : 'percentage'
          },
          {
            name: ValueType.Overall,
            value: companyVariables.useLevels ? (entry.overallScore?.level || 0) : (entry.overallScore?.percentage || 0),
            dataType: companyVariables.useLevels ? 'none' : 'percentage'
          },
        ],
      }));

      setIncrements(lineChartIncrements(tempData));

      setData(tempData);

      setLoading(false);
    }

    getData();
  }, [dateRange, companyVariables.slug, entity]);

  if (loading) return <SkeletonLoader height="405" />;

  return (
    <div className="card">
      {!data.length ? (
        <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} badgeIconWidth={470} title="No skill levels found" />
      ) : (
        <React.Fragment>
          <h3 className="heading3" style={{ marginBottom: 15 }}>
            Skill scores over time
          </h3>

          <LineChart fill={false} width={1800} height={550} colorMap={colorMap} shownLines={shownLines} yIncrements={increments} data={data} />

          <SkillLevelOverTimeWidgetLegend>
            {Array.from(colorMap.entries()).map(([name, colour]) => (
              <SkillLevelOverTimeWidgetLegendElement
                key={name}
                className={classNames({ active: shownLines.includes(name), forceActive: shownLines.length === 1 })}
                colour={colour}
                onClick={() => {
                  if (!shownLines.includes(name)) {
                    setShownLines([...shownLines, name]);
                    return;
                  }

                  if (shownLines.length === 1) return;

                  setShownLines(shownLines.filter(line => line !== name));
                }}
              >
                {`${name} ${companyVariables.useLevels ? 'Skill Level' : 'Skill Percentage'}`}
              </SkillLevelOverTimeWidgetLegendElement>
            ))}
          </SkillLevelOverTimeWidgetLegend>
        </React.Fragment>
      )}
    </div>
  );
}


