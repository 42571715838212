import React, { useEffect, useReducer } from 'react';
import { ILeaderboardProps as ILeaderboardProps } from './leaderboard.models';
import { skillScoreRounding } from '../../../lib/skill-score-rounding';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { themedAssetUrl } from '../../../lib/theme-asset-url';
import { useAppActions, useAppState } from '../../../overmind';
import { SkeletonLoader } from '../../../components';
import { OrganizationLevelType } from '../../../enums';
import { LeaderboardSearchParams } from '../../../models/overmind/search-params';
import * as store from './leaderboard.store';
import { LeaderboardWidgetContainer } from './leaderboard.styles';
import { Pager, PillButton, SkillLevel, SkillPercentage, Table, TableColumn, TableRow, scorePercentageRAGColour, scoreLevelRAGColour } from '@keplerco/core';

export function LeaderboardWidget({ entity }: ILeaderboardProps): JSX.Element {
  const { companyVariables, dateRange } = useAppState();
  const actions = useAppActions();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  useEffect(() => {
    if (!entity) return;

    const searchParams: LeaderboardSearchParams = {
      startDate: dateRange?.from?.toJSON(),
      endDate: dateRange?.to?.toJSON(),
      organizationLevel: entity.organizationLevel,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      skillSlug: undefined,
      skillSubTypeSlug: undefined,
      page: state.request?.page ?? 1,
      pageSize: state.request?.pageSize ?? 10,
    };
    if (entity.organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = entity.entity.slug;
    if (entity.organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = entity.entity.slug;
    if (entity.organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug = entity.entity.slug;

    dispatch({ type: store.PeopleLeaderboardActionTypes.SetRequest, payload: searchParams });
  }, [dateRange, entity, state.leaderboardType]);

  useEffect(() => {
    async function getData() {
      if (!state.request) return;

      dispatch({ type: store.PeopleLeaderboardActionTypes.SetLoading, payload: true });

      const data = state.leaderboardType === store.LeaderboardType.SkillScore
        ? await actions.analyticsGetSkillScoresLeaderboard(state.request)
        : await actions.analyticsGetKeplerPointsLeaderboard(state.request);
      dispatch({ type: store.PeopleLeaderboardActionTypes.SetData, payload: data });

      dispatch({ type: store.PeopleLeaderboardActionTypes.SetLoading, payload: false });
    }

    getData();
  }, [state.request]);

  function onPageChangeHandler(page: number) {
    if (!state.request || page === state.request.page) return;

    dispatch({ type: store.PeopleLeaderboardActionTypes.SetRequest, payload: { ...state.request, page } });
  }

  function generateRank(index: number): string {
    const pageRank = index + 1;
    const page = state.request?.page ?? 1;
    const pageSize = state.request?.pageSize ?? 10;
    const pageSizeIncrement = page * pageSize;
    const overallRank = pageSizeIncrement + pageRank - pageSize;
    return overallRank.toString().padStart(4, '0');
  }

  if (state.loading) return <SkeletonLoader height="578px" />;

  return (
    <div className="card">
      <LeaderboardWidgetContainer>
        <h3 className="heading3">Leaderboard</h3>

        <div className="pillButtonsContainer">
          <PillButton
            label={store.LeaderboardType.SkillScore}
            active={state.leaderboardType === store.LeaderboardType.SkillScore}
            backgroundColour="borders"
            square
            onClick={() => dispatch({ type: store.PeopleLeaderboardActionTypes.SetLeaderboardType, payload: store.LeaderboardType.SkillScore })}
          />

          <PillButton
            label={store.LeaderboardType.KeplerPoints}
            active={state.leaderboardType === store.LeaderboardType.KeplerPoints}
            backgroundColour="borders"
            square
            onClick={() => dispatch({ type: store.PeopleLeaderboardActionTypes.SetLeaderboardType, payload: store.LeaderboardType.KeplerPoints })}
          />
        </div>

        <div style={{ minHeight: 425 }}>
          {!state.data?.totalCount ? (
            <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} title="No leaderboard results" />
          ) : (
            <React.Fragment>
              <Table>
                {state.data.values.map((value, index) => (
                  <TableRow id={value.slug} key={value.slug}>
                    <TableColumn id="Rank" label="Rank">
                      {generateRank(index)}
                    </TableColumn>

                    <TableColumn id="Name" label="Name">
                      {value.name}
                    </TableColumn>

                    <TableColumn
                      id={state.leaderboardType === 'Kepler Points' ? 'Points' : companyVariables.useLevels ? 'Level' : 'Percentage'}
                      label={state.leaderboardType === 'Kepler Points' ? 'Points' : companyVariables.useLevels ? 'Level' : 'Percentage'}
                    >
                      {state.leaderboardType === 'Kepler Points' ? (
                        skillScoreRounding(value.amount!)
                      ) : (
                        <React.Fragment>
                          {companyVariables.useLevels ? (
                            <SkillLevel
                              level={value.score?.level ?? companyVariables.minLevel}
                              minLevel={companyVariables.minLevel}
                              maxLevel={companyVariables.maxLevel}
                              noLevel={!value.score}
                              dotColour={scoreLevelRAGColour(value.score?.level ?? 0, companyVariables.minLevel, companyVariables.maxLevel)}
                            />
                          ) : (
                            <SkillPercentage
                              percentage={value.score?.percentage ?? 0}
                              noPercentage={!value.score}
                              barColour={scorePercentageRAGColour(value.score?.percentage ?? 0)}
                            />
                          )}
                        </React.Fragment>
                      )}
                    </TableColumn>
                  </TableRow>
                ))}
              </Table>

              {state.data.totalPages > 1 && (
                <Pager
                  activePageNumber={state.request?.page ?? 1}
                  pageCount={state.data.totalPages}
                  onPageChange={onPageChangeHandler}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </LeaderboardWidgetContainer>
    </div>
  );
}

