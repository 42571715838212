import { Anchor, Button, FocusPanelHeader } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { EntitySelectionList } from '../../../../components/entity-selection/entity-selection-list/entity-selection-list';
import { useAppActions, useAppState } from '../../../../overmind';
import { IEditPeopleLayoutProps, IPerson } from './people-and-skills.models';
import { BaseSearchRequest, EmployeeSearchResponse } from '../../../../models';
import { IChip } from '../../../../components/entity-selection/entity-selection.models';

const pageSize = 6;

export function EditPeopleLayout({ peopleToEdit, setPeopleToEdit, setPersonToEdit, allowManagerReview, allowPeerReview, onBack, onDone }: IEditPeopleLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<BaseSearchRequest>({
    companySlug: companyVariables.slug,
    page: 1,
    pageSize,
    sortAscending: true,
  });
  const [people, setPeople] = useState<EmployeeSearchResponse>();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const response = await actions.searchPeople(request);
      setPeople(response);
      setLoading(false);
    }

    getData();
  }, [request]);

  return (
    <div className="panelContent">
      <FocusPanelHeader
        supertitle="Assessments"
        title="Add People"
        subtitle="Search for and add people to the assessment"
        divider
      />

      <div className="dialogBodyLayout" style={{ marginTop: 30, display: 'flex', flexDirection: 'column', gap: 30 }}>
        <EntitySelectionList
          loading={loading}
          label="Search for people"
          entities={people?.employees.map(employee => {
            const person: IPerson = peopleToEdit.find(personToEdit => personToEdit.id === employee.id) ?? {
              ...employee,
              selected: false,
              manager: false,
              skills: [],
            };

            let chips: IChip[] = [{
              label: `${person.skills.length} skill(s)`,
              backgroundColour: 'g',
            }];

            if (person.selected) {
              if (allowManagerReview && !allowPeerReview && person.manager) {
                chips = [];
              }
              if (person.manager) {
                chips.unshift({
                  label: 'Manager',
                  backgroundColour: 'baby-blue',
                });
              }


            }
            return ({
              slug: person.id.toString(),
              title: `${person.firstName} ${person.lastName}`,
              subtitle: `${person.department.name || 'No department'} | ${person.team.name || 'No team'}`,
              selected: person.selected,
              onClick: () => {
                if (person.selected) {
                  setPeopleToEdit(currentState => {
                    if (!currentState) return;
                    let nextState: IPerson[] = structuredClone(currentState);
                    nextState = nextState.filter(selectedPerson => selectedPerson.id !== person.id);
                    return nextState;
                  });
                } else {
                  const nextPerson = { ...person, selected: true };
                  setPeopleToEdit(currentState => {
                    if (!currentState) return;
                    const nextState: IPerson[] = structuredClone(currentState);
                    nextState.push(nextPerson);
                    return nextState;
                  });
                  setPersonToEdit && setPersonToEdit(person);
                }
              },

              chips,
            });
          }) ?? []}
          page={request.page ?? 1}
          totalPages={people?.totalPages ?? 0}
          onInput={search => setRequest(currentState => ({ ...currentState, search, page: 1 }))}
          onPageChange={page => setRequest(currentState => ({ ...currentState, page }))}
        />
      </div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button
          type="button"
          onClick={() => onDone()}
        >
          Done
        </Button>
      </footer>
    </div>
  );
}