import React, { useState } from 'react';
import styles from './bulk-upload-preview.module.css';
import { useAppActions, useAppState } from '../../../overmind';
import { Anchor, Button, Chip, colourString, KebabMenu, ListItemLayout, ListLayout, Modal, search, SearchBar, Tab, Tabs, useMatchScreenWidth } from '@keplerco/core';
import { UserBulkUploadRow } from '../../../models/bulk-uploads/bulk-upload-dto';
import { BulkUploadType } from '../../../enums/bulk-upload-type';
import { ConfirmationModalLayout } from '../../../widgets/layouts';
import { BulkUploadResultModal } from './bulk-upload-result/bulk-upload-result.modal';
import { useNavigate, useParams } from 'react-router-dom';
import { PagePath } from '../../../navigation/navigation.enums';
import { FetchType } from '../../../enums';
import { IBulkUploadPreviewWidgetProps } from '../bulk-upload.models';
import { QueueItemPriority, QueueItemType } from '../../notification-hub/notification-hub.models';
import classNames from 'classnames';

enum PreviewTabs {
  ViewAll = 'View All',
  Valid = 'Valid',
  ErrorFound = 'Error found',
  Edited = 'Edited',
}

export function BulkUploadPreviewWidget(props: IBulkUploadPreviewWidgetProps): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();
  const actions = useAppActions();

  const [showResultModal, setShowResultModal] = useState(false);
  const [showUploadConfirmationModal, setShowUploadConfirmationModal] = useState(false);
  const [showRemoveAllConfirmationModal, setShowRemoveAllConfirmationModal] = useState(false);

  const { companyVariables } = useAppState();
  const [searchValue, setSearchValue] = useState<string>('');
  const [activeTab, setActiveTab] = useState<PreviewTabs>(PreviewTabs.ViewAll);
  const isMobile = useMatchScreenWidth('mobile');

  const rows = props.data.rows ?? [];

  function getFilteredData(): UserBulkUploadRow[] {
    let filteredRows: UserBulkUploadRow[];

    switch (activeTab) {
      case PreviewTabs.Valid: {
        filteredRows = rows.filter(row => row.isValid && !props.editedRows.has(row.email));
        break;
      }

      case PreviewTabs.ErrorFound: {
        filteredRows = rows.filter(row => !row.isValid && !props.editedRows.has(row.email));
        break;
      }

      case PreviewTabs.Edited: {
        filteredRows = rows.filter(row => props.editedRows.has(row.email));
        break;
      }

      case PreviewTabs.ViewAll:
      default: {
        filteredRows = rows;
        break;
      }
    }

    filteredRows = search(filteredRows, searchValue, 'firstName', 'lastName', 'jobTitle', 'email');

    return filteredRows;
  }

  const filteredData = getFilteredData();

  const hasValidOrEditedRows = rows.some(row => row.isValid || props.editedRows.has(row.email));

  function onCloseBulkUploadResultModal() {
    const unsuccessfulRows = rows.filter(row => !row.isValid);

    if (unsuccessfulRows.length === 0) {
      navigate(`${PagePath.administrationBase}${PagePath.administrationPeople}`.replace(':companySlug', params.companySlug!));
    }

    props.setData({
      ...props.data,
      rows: unsuccessfulRows,
    });

    setShowUploadConfirmationModal(false);
    setShowResultModal(false);
    setShowRemoveAllConfirmationModal(false);
  }

  function deleteRow(rowNumber: number) {
    const updatedRows = rows.filter(row => row.rowNumber !== rowNumber);
    props.setData({
      ...props.data,
      rows: updatedRows,
    });
  }

  async function handleBulkUpload() {
    actions.startLoader({ path: PagePath.administrationPeopleBulkUpload, type: FetchType.Sending });
    const response = await actions.bulkUpload({
      bulkUploadType: BulkUploadType.Users,
      companySlug: companyVariables.slug!,
      data: rows,
    });

    if (response.error) {
      actions.addNotification({
        id: Date.now().toString(),
        title: `Bulk upload failed. Please contact support`,
        message: `Bulk upload failed: ${response.error.detail || 'An unknown error occurred'}`,
        priority: QueueItemPriority.Toast,
        type: QueueItemType.Error,
        active: true,
      });
    } else if (response?.data?.rows?.length) {
      props.setData({
        ...props.data,
        rows: response.data.rows,
      });

      props.setEditedRows(new Set());

      if (response.data.rows.some(row => row.isValid)) {
        setShowResultModal(true);
      }
    }

    setShowUploadConfirmationModal(false);
    setShowRemoveAllConfirmationModal(false);
    actions.stopLoader(PagePath.administrationPeopleBulkUpload);
  }



  return (
    <>
      {!!rows.length && (
        <Tabs static>
          <Tab id={PreviewTabs.ViewAll} name={`${PreviewTabs.ViewAll} (${rows.length})`} active={activeTab === PreviewTabs.ViewAll} onClick={() => setActiveTab(PreviewTabs.ViewAll)} />

          <Tab id={PreviewTabs.Valid} name={`${PreviewTabs.Valid} (${rows.filter(row => row.isValid).length})`} active={activeTab === PreviewTabs.Valid} disabled={!rows.filter(row => row.isValid).length} onClick={() => setActiveTab(PreviewTabs.Valid)} />

          <Tab id={PreviewTabs.ErrorFound} name={`${PreviewTabs.ErrorFound} (${rows.filter(row => !row.isValid).length})`} active={activeTab === PreviewTabs.ErrorFound} disabled={!rows.filter(row => !row.isValid).length} onClick={() => setActiveTab(PreviewTabs.ErrorFound)} />
        </Tabs>
      )}

      <div className={styles.searchContainer} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SearchBar label="Search" onInput={event => setSearchValue((event.target as HTMLInputElement).value)} />
        <Anchor onClick={() => setShowRemoveAllConfirmationModal(true)}>Remove All</Anchor>
      </div>

      <div className={styles.scrollableList}>
        <ListLayout>
          {filteredData.map(item => (
            <ListItemLayout key={item.rowNumber}>
              <div className={classNames('card', styles.card)} style={{ borderLeftColor: colourString(props.editedRows.has(item.email) ? 'grc_3' : item.isValid ? 'g' : 'r') }}>
                <div className={styles.cardColumn} style={{ maxWidth: 100 }}>
                  {item.firstName}
                </div>
                {!isMobile && (
                  <>
                    <div className={styles.cardColumn} style={{ width: 100 }}>
                      {item.lastName}
                    </div>
                    <div className={styles.cardColumn} style={{ width: 100 }}>
                      {item.jobTitle}
                    </div>
                    <div className={styles.cardColumn} style={{ width: 150 }}>
                      {item.email ? item.email : '[Email Address]'}
                    </div>
                  </>
                )}
                <div style={{ display: 'flex', justifyContent: 'end', minWidth: 100 }}>
                  <Chip label={props.editedRows.has(item.email) ? 'Edited' : item.isValid ? 'Valid' : 'Error found'} variant="tiny" backgroundColour={props.editedRows.has(item.email) ? 'blue' : item.isValid ? 'g' : 'r'} />
                </div>

                <KebabMenu
                  items={[
                    {
                      label: 'Edit',
                      onClick: () => props.setSelectedRow(item)
                    },
                    {
                      label: 'Delete',
                      onClick: () => deleteRow(item.rowNumber)
                    },
                  ]}
                />
              </div>
            </ListItemLayout>
          ))}
        </ListLayout>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '100px' }}>
        <Anchor className={styles.anchor} onClick={() => props.setData(undefined)}>
          Cancel
        </Anchor>

        <Button type="button" disabled={!hasValidOrEditedRows} filled onClick={() => setShowUploadConfirmationModal(true)}>
          Confirm Upload
        </Button>
      </div>

      <Modal open={showUploadConfirmationModal} onClose={() => setShowUploadConfirmationModal(false)}>
        <ConfirmationModalLayout
          onClickCancel={() => setShowUploadConfirmationModal(false)}
          onClickSubmit={handleBulkUpload}
          title="Upload confirmation"
          subtitle="Are you sure you want to upload the file? All errors will not be uploaded."
        />
      </Modal>

      <Modal open={showRemoveAllConfirmationModal} onClose={() => setShowRemoveAllConfirmationModal(false)}>
        <ConfirmationModalLayout
          onClickCancel={() => setShowRemoveAllConfirmationModal(false)}
          submitButtonText="Remove"
          onClickSubmit={() => {
            props.reset();
            setSearchValue('');
          }}
          title="Remove all"
          subtitle="Are you sure you want to clear your preview and re-upload a new file?"
        />
      </Modal>

      <Modal size="extralarge" open={showResultModal} onClose={onCloseBulkUploadResultModal}>
        <BulkUploadResultModal
          data={{ rows: rows }}
          onClose={onCloseBulkUploadResultModal}
        />
      </Modal>
    </>
  );
}
