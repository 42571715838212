import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FetchType } from '../../enums';
import { DigitalSignatureType } from '../../enums/digital-signature-type';
import { ISettings } from '../../models/settings';
import { useAppActions, useAppState } from '../../overmind';
import { PagePath } from '../../navigation/navigation.enums';
import { Button, Checkbox, FormControl, RequiredValidator, Textfield } from '@keplerco/core';

export function PDFReportModalLayout(props: { path: PagePath.dashboard | PagePath.yourSkillsBase | PagePath.analysisInterimDownload; from: string | undefined; to: string; onClick: () => void }): JSX.Element {
  const actions = useAppActions();
  const { settings } = useAppState();

  const { control, handleSubmit, getValues } = useForm<FieldValues>({ mode: 'onChange' });

  async function onSubmitHandler(values: any) {
    if (!settings) return;

    actions.startLoader({ path: props.path, type: FetchType.Sending });

    const model: ISettings = {
      ...settings,
      phoneNumber: values.phoneNumber,
      cpdRegistrationNumber: values.cpdRegistrationNumber,
    };

    await actions.saveDigitalSignature(DigitalSignatureType.CpdActivityHonestyPledge);
    await actions.saveUserProfile(model);
    props.onClick();
  }

  return (
    <div className="modalContent">
      <header className="dialogHeaderLayout modalHeaderLayout">
        <h2 className="heading2">We'll need some info before we generate your report</h2>
      </header>

      <form id="saveDigitalSignature" onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="card">
          <div className="dialogBodyLayout">
            <FormControl
              name="phoneNumber"
              control={control}
              rules={new RequiredValidator('Enter your contact number')}
              defaultValue={settings?.phoneNumber ?? ''}
              render={({ field, fieldState }) => {
                return <Textfield {...field} label="Contact Number" haserror={!!fieldState.error} type="number" responsive />;
              }}
            />

            <FormControl
              name="cpdRegistrationNumber"
              control={control}
              rules={new RequiredValidator('Enter your Professional body membership number')}
              defaultValue={settings?.cpdRegistrationNumber ?? ''}
              render={({ field, fieldState }) => {
                return <Textfield {...field} label="Professional body membership number" haserror={!!fieldState.error} type="text" responsive />;
              }}
            />

            <FormControl
              name="confirmHonesty"
              control={control}
              rules={new RequiredValidator('Complete your declaration')}
              paddingBottom={0}
              render={({ field }) => {
                return (
                  <Checkbox {...field} id="confirmHonesty" checked={getValues('confirmHonesty')}>
                    <span style={{ textAlign: 'start' }}>
                      I confirm that the information included in this report (including but not limited to learning related to Business, Personal, and Professional Ethics) is an accurate reflection of my CPD activities {!!props.from ? `from ${props.from} to ${props.to}` : `up to ${props.to}`}.
                    </span>
                  </Checkbox>
                );
              }}
            />
          </div>
        </div>

        <footer className="dialogFooterLayout modalFooterLayout">
          <Button type="button" filled>
            Submit
          </Button>
        </footer>
      </form>
    </div>
  );
}

