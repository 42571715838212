import { Anchor, GridItemLayout, GridLayout, ListItemLayout, ListLayout, RadioButtonCard, TextField, ToggleCard, useMatchScreenWidth } from '@keplerco/core';
import React, { Fragment, useEffect, useState } from 'react';
import { AssessmentPreferencesRequest, AssessmentPreferencesResponse } from '../../../../models/overmind/assessment-preferences';
import classNames from 'classnames';
import { useAppActions, useAppState } from '../../../../overmind';
import { AssessmentType } from '../../../../enums/assessment-type';
import styles from './assessments-wizard-preferences.module.css';
import { SkeletonLoader } from '../../../../components';
import { SkeletonLoaderRow } from '../../../../components/loading-handling/loaders/skeleton-loader/skeleton-loader.styles';

enum ErrorMessage {
  NoPeerToggle = 'Activate at least one assessment toggle',
}

export function AssessmentsWizardPreferencesWidget({ assessmentSlug, assessmentType, preferences, setPreferences, onSaveAndContinue }: { assessmentSlug: string | undefined, assessmentType: AssessmentType, preferences: AssessmentPreferencesResponse, setPreferences: React.Dispatch<React.SetStateAction<AssessmentPreferencesResponse>>, onSaveAndContinue: () => void }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');

  const [loading, setLoading] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  useEffect(() => {
    setErrorMessage(assessmentType === AssessmentType.PeerEndorsement && !preferences.allowSelfReview && !preferences.allowPeerReview && !preferences.allowManagerReview ? ErrorMessage.NoPeerToggle : undefined);
  }, [preferences.allowSelfReview, preferences.allowPeerReview, preferences.allowManagerReview]);

  if (loading) {
    return assessmentType === AssessmentType.PeerEndorsement ? (
      <Fragment>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
          <SkeletonLoader height="70px" />
          <SkeletonLoader height="70px" />
          <SkeletonLoader height="70px" />
        </div>

        <div style={{ marginTop: 75, display: 'flex', flexDirection: 'column', gap: 10 }}>
          <SkeletonLoader height="20px" />

          <SkeletonLoader height="20px" />

          <SkeletonLoaderRow>
            <SkeletonLoader />

            <SkeletonLoader />
          </SkeletonLoaderRow>
        </div>

        <div style={{ marginTop: 50, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <SkeletonLoader width="150px" height="25px" />
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <SkeletonLoader width="100px" height="20px" />

          <SkeletonLoader height="70px" />
        </div>

        <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', gap: 10 }}>
          <SkeletonLoader width="150px" height="20px" />

          <SkeletonLoaderRow>
            <SkeletonLoader height="45px" />

            <SkeletonLoader height="45px" />
          </SkeletonLoaderRow>
        </div>

        <div style={{ marginTop: 75, display: 'flex', flexDirection: 'column', gap: 10 }}>
          <SkeletonLoader height="20px" />

          <SkeletonLoader height="20px" />

          <SkeletonLoaderRow>
            <SkeletonLoader />

            <SkeletonLoader />
          </SkeletonLoaderRow>
        </div>

        <div style={{ marginTop: 50, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <SkeletonLoader width="150px" height="25px" />
        </div>
      </Fragment>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
      {assessmentType === AssessmentType.PeerEndorsement ? (
        <div>
          <ListLayout>
            <ListItemLayout>
              <ToggleCard
                id="allowSelfReview"
                value={preferences?.allowSelfReview ?? false}
                onChange={(_, value) => {
                  setPreferences(currentState => {
                    const nextState = structuredClone(currentState);
                    nextState.allowSelfReview = value;
                    return nextState;
                  });
                }}
              >
                <div className={styles.toggleCardChildren} style={{ gridTemplateColumns: !isMobile ? '200px 1fr' : '1fr' }}>
                  <h6 className="subtitle">Self Assessment</h6>

                  {!isMobile && <p className="caption1" style={{ color: 'var(--accent-2' }}>Participants will assess themselves against assigned skills</p>}
                </div>
              </ToggleCard>
            </ListItemLayout>

            <ListItemLayout>
              <ToggleCard
                id="allowManagerReview"
                value={preferences?.allowManagerReview ?? false}
                onChange={(_, value) => {
                  setPreferences(currentState => {
                    const nextState = structuredClone(currentState);
                    nextState.allowManagerReview = value;
                    return nextState;
                  });
                }}
              >
                <div className={styles.toggleCardChildren} style={{ gridTemplateColumns: !isMobile ? '200px 1fr' : '1fr' }}>
                  <h6 className="subtitle">Manager Assessment</h6>

                  {!isMobile && (
                    <p className="caption1" style={{ color: 'var(--accent-2' }}>
                      The manager and the employee will be given a chance to rate the employee's skills
                    </p>
                  )}
                </div>
              </ToggleCard>
            </ListItemLayout>

            <ListItemLayout>
              <ToggleCard
                id="allowPeerReview"
                value={preferences?.allowPeerReview ?? false}
                onChange={(_, value) => {
                  setPreferences(currentState => {
                    const nextState = structuredClone(currentState);
                    nextState.allowPeerReview = value;
                    return nextState;
                  });
                }}
              >
                <div className={styles.toggleCardChildren} style={{ gridTemplateColumns: !isMobile ? '200px 1fr' : '1fr' }}>
                  <h6 className="subtitle">Peer Assessment</h6>

                  {!isMobile && <p className="caption1" style={{ color: 'var(--accent-2' }}>The entire peer group will rate one another on the assigned skills</p>}
                </div>
              </ToggleCard>
            </ListItemLayout>
          </ListLayout>

          <div className={classNames('formErrorMessage', { invisible: !dirty || !errorMessage })} style={{ marginTop: 15 }}>
            {errorMessage}&nbsp;
          </div>
        </div>
      ) : (
        <Fragment>
          <div>
            <div className="heading5">Reassessment</div>

            <div className="row">
              <ToggleCard
                id="allowReassessment"
                value={preferences?.allowReassessment ?? false}
                onChange={(_, value) => {
                  setPreferences(currentState => {
                    const nextState = structuredClone(currentState);
                    nextState.allowReassessment = value;
                    return nextState;
                  });
                }}
              >
                <h6 className="subtitle">Allow reassessment</h6>

                {!isMobile && <p className="caption1" style={{ color: 'var(--accent-2' }}>Allow the learners to reset and retake the assessment by themselves</p>}
              </ToggleCard>
            </div>
          </div>

          <div>
            <div className="heading5">Assessment scores</div>

            <div className="row">
              <div className="column">
                <TextField
                  label="Minimum score"
                  type="number"
                  defaultValue={preferences.minScore}
                  min={0}
                  onChange={event => {
                    setPreferences(currentState => {
                      const nextState = structuredClone(currentState);
                      nextState.minScore = parseInt(event.target.value);
                      return nextState;
                    });
                  }}
                  responsive
                  validation={{
                    dirty: dirty,
                    invalid: preferences.minScore === undefined || preferences.minScore >= preferences.maxScore || preferences.minScore < 0,
                    message: 'Minimum score is required and must be less than maximum score and greater than 0'
                  }}
                />
              </div>

              <div className="column">
                <TextField
                  label="Maximum score"
                  type="number"
                  defaultValue={preferences.maxScore}
                  max={100}
                  onChange={event => {
                    setPreferences(currentState => {
                      const nextState = structuredClone(currentState);
                      nextState.maxScore = parseInt(event.target.value);
                      return nextState;
                    });
                  }}
                  responsive
                  validation={{
                    dirty: dirty,
                    invalid: preferences.maxScore === undefined || preferences.minScore >= preferences.maxScore || preferences.maxScore > 100,
                    message: 'Maximum score is required and must be greater than minimum score and less than 100'
                  }}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}

      <div>
        <div className="heading5">Do you want to force participants to complete of this assessment once activated?</div>

        {!isMobile && (
          <p className="caption1" style={{ color: 'var(--accent-2)', marginBottom: 10 }}>
            Force participants to complete an assessment, the next time someone logs in they will be required to complete the assessment before continuing.
          </p>
        )}

        <GridLayout columnCount={2}>
          <GridItemLayout>
            <RadioButtonCard
              id="forceAssessmentTrue"
              checked={!!preferences?.forceAssessment}
              onChange={() => {
                setPreferences(currentState => {
                  const nextState = structuredClone(currentState);
                  nextState.forceAssessment = true;
                  return nextState;
                });
              }}
            >
              <div className="card">Yes, make this compulsory</div>
            </RadioButtonCard>
          </GridItemLayout>

          <GridItemLayout>
            <RadioButtonCard
              id="forceAssessmentFalse"
              checked={!preferences?.forceAssessment}
              onChange={() => {
                setPreferences(currentState => {
                  const nextState = structuredClone(currentState);
                  nextState.forceAssessment = false;
                  return nextState;
                });
              }}
            >
              <div className="card">No, make optional</div>
            </RadioButtonCard>
          </GridItemLayout>
        </GridLayout>

        <p className={classNames('caption1', { invisible: !preferences.forceAssessment })} style={{ color: 'var(--accent-2)' }}>
          Great! We'll let people know to complete the assessment.
        </p>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Anchor
          arrow
          onClick={async () => {
            setDirty(true);

            if (!assessmentSlug) return;
            if (!!errorMessage) return;
            if (assessmentType !== AssessmentType.PeerEndorsement && (preferences.minScore === undefined || preferences.maxScore === undefined || preferences.minScore >= preferences.maxScore || preferences.minScore < 0 || preferences.maxScore > 100)) return;

            setLoading(true);

            const request: AssessmentPreferencesRequest & AssessmentPreferencesResponse = {
              ...preferences,
              assessmentSlug,
              companySlug: companyVariables.slug!
            };
            await actions.putAssessmentPreferences(request);

            setLoading(false);

            onSaveAndContinue();
          }}
        >
          Save & Continue
        </Anchor>
      </div>
    </div >
  );
}