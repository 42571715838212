import React, { useState } from 'react';
import * as theme from '../../../theme';
import { useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { KeplerState } from '../../../models/kepler-state';
import { FetchType, SetPasswordStatus } from '../../../enums';
import { AccountPageInstruction, AccountPageContainer, AccountPageForm, AccountPageNavigationToggler } from '../account.styles';
import { ResetPassword } from '../../../models/view/reset-password';
import { AccountPageLoaderLayer } from '../../../components/loading-handling/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { PagePath } from '../../../navigation/navigation.enums';
import { Button, EmailValidator, FormControl, Textfield } from '@keplerco/core';

export function ForgotPasswordPage(): JSX.Element {
  const actions = useAppActions();
  const { setPasswordStatus, allowAccountCreation } = useAppState<KeplerState>();
  const navigate = useNavigate();
  const [sentForgotPassword, setState] = useState(false);
  const { control, handleSubmit } = useForm<any>({ reValidateMode: 'onChange' });

  return (
    <theme.ShapesBackgroundWrapper>
      <AccountPageContainer>
        <div className="card">
          <AccountPageLoaderLayer path={PagePath.accountForgotPassword}>
            <AccountPageNavigationToggler className="showDesktop">
              {allowAccountCreation ? (
                <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountRegister}`)}>
                  Not registered? <u>Create an account</u>
                </span>
              ) : (
                <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountActivate}`)}>
                  Not registered? <u>Activate your account</u>
                </span>
              )}
            </AccountPageNavigationToggler>

            <h1 className="heading1">Forgot your password?</h1>

            {!sentForgotPassword && (
              <React.Fragment>
                <div style={{ height: 5 }} />

                <h5 className="heading5">It happens... to the best of us.</h5>

                <div style={{ height: 30 }} />

                <h6 className="subtitle" style={{ margin: 'auto', maxWidth: 490 }}>
                  Enter your email address, and we'll send you an email with a link to reset your password.
                </h6>

                <AccountPageForm
                  id="forgotPassword"
                  autoComplete="off"
                  onSubmit={handleSubmit(async (value: ResetPassword) => {
                    actions.startLoader({ path: PagePath.accountForgotPassword, type: FetchType.PageFetching });

                    const result = await actions.forgotPassword(value);
                    setState(result);

                    actions.stopLoader(PagePath.accountForgotPassword);
                  })}
                >
                  <FormControl paddingBottom={0} name="email" control={control} rules={new EmailValidator('Enter your email')} render={({ field, fieldState }) => <Textfield {...field} autoFocus haserror={!!fieldState.error} label="Email" responsive />} />

                  <Button type="button" fullWidthMobile>
                    Send Reset Instructions
                  </Button>

                  <AccountPageNavigationToggler className="showMobile bottom">
                    {allowAccountCreation ? (
                      <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountRegister}`)}>
                        Not registered? <u>Create an account</u>
                      </span>
                    ) : (
                      <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountActivate}`)}>
                        Not registered? <u>Activate your account</u>
                      </span>
                    )}
                  </AccountPageNavigationToggler>

                  {setPasswordStatus === SetPasswordStatus.Failed && <div className="formErrorMessage">There was a problem with resetting your password</div>}
                </AccountPageForm>
              </React.Fragment>
            )}

            {!!sentForgotPassword && (
              <AccountPageInstruction>
                <div style={{ height: 5 }} />

                <h5 className="heading5">Thanks!</h5>

                <div style={{ height: 30 }} />

                <h6 className="subtitle">We've sent you an email with a link to reset your password.</h6>
                <br />
                <h6 className="subtitle">If you don't find it, check your spam.</h6>
              </AccountPageInstruction>
            )}
          </AccountPageLoaderLayer>
        </div>
      </AccountPageContainer>
    </theme.ShapesBackgroundWrapper>
  );
}

