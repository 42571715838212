import { OrganizationLevelType } from '../../../enums';
import { EntityScoreDetail, EntityScoreResult } from '../../../models/overmind/analytics';
import { HeatmapType, ScoreItem } from './heatmap.models';

function getAverageLabel(title: string | undefined): string {
  if (title === 'Department') return 'Company average';
  if (title === 'Team') return 'Department average';
  return 'Team average';
}

function formatScore(score: EntityScoreDetail['score'], useLevels: boolean): number | string {
  if (useLevels) {
    return !!score ? score.level : 'Not assessed';
  }
  return !!score ? score.percentage : 'Not assessed';
}

export function transformScores(scores: EntityScoreResult[], title: string | string, useLevels: boolean, isAverageScore?: boolean): ScoreItem[] {
  return scores.map(personScore => {
    const personScoresObj: ScoreItem = { [`${title}`]: isAverageScore ? getAverageLabel(title) : personScore.name, slug: personScore.slug };
    personScore.scores.forEach(score => {
      personScoresObj[score.name] = formatScore(score.score, useLevels);
    });
    return personScoresObj;
  });
}

export function getEntityTitle(organizationLevel: OrganizationLevelType | undefined): HeatmapType {
  switch (organizationLevel) {
    case OrganizationLevelType.Department:
      return 'Department';
    case OrganizationLevelType.Team:
      return 'Team';
    default:
      return 'People';
  }
}

