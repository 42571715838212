import React from 'react';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { Dot } from '../../../widgets/analytics/ranked-entities/horizontal-bar-chart/dot';
import styles from './recharts-tooltip.module.css';

export function RechartsTooltip({ active, payload }: TooltipProps<ValueType, NameType>): JSX.Element {
  if (active) {
    return (
      <div className={styles.tooltipWrapper}>
        {payload && payload[0] && (
          <>
            <h6 key={payload[0].payload.name} className='heading6'>
              {payload[0].payload.name}
            </h6>
            {payload.map((item) => {
              return (
                <div key={item.name} className={styles.tooltipText}>
                  <span><Dot fill={item.color} /></span>
                  <span className='caption1'>{`${item.name}: ${item.value}`}</span>
                </div>
              );
            })}
          </>
        )
        }
      </div>
    );
  }

  return <></>;
}