import React from 'react';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { PagerNavigationConnector } from '../../../components/pager-connector/pager-connector';
import { useAppActions } from '../../../overmind';
import { ActivationStatus, FetchType } from '../../../enums';
import { PeopleTableNameCellContentContainer } from './people.cms.styles';
import { PersonActivationStatusIcon, createDepartmentLink, createTeamLink } from './people.cms.helpers';
import { IPeopleTableCMSLayoutProps } from './people.cms.models';
import { PersonListItem } from '../../../models/view/employee-search-response';
import { Anchor, Chip, KebabMenu, MenuItem, Pager, Table, TableColumn, TableRow, useMatchScreenWidth } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';

export function PeopleTableCMSLayout(props: IPeopleTableCMSLayoutProps): JSX.Element {
  const actions = useAppActions();

  const keplerNavigate = useKeplerNavigate();
  const isMobile = useMatchScreenWidth('mobile');

  // tables
  async function onSortHandler(column: any) {
    actions.startLoader({ path: props.path, type: FetchType.PageFetching });

    const isCurrentlyAscending = props.request.sortAscending;
    const currentSortField = props.request.sortField;

    const newSortDirection = currentSortField === column ? !isCurrentlyAscending : true;

    props.setRequest({ ...props.request, sortField: column, sortAscending: newSortDirection });
  }

  async function onPageChangeHandler(page: number) {
    if (page === props.request.page) return;
    props.setSelectedRows([]);

    actions.startLoader({ path: props.path, type: FetchType.PageFetching });
    props.setRequest({ ...props.request, page });
  }

  async function onClickPersonHandler(id: string | undefined) {
    if (!id) return;

    actions.startLoader({ path: props.path, type: FetchType.Custom });
    props.setPersonToManage({ show: true, data: id });
    actions.stopLoader(props.path);
  }

  // kebab menus
  async function onClickArchiveHandler(person: PersonListItem) {
    if (!person.id) return;
    props.setPersonToArchive(person);
  }

  async function onClickUnarchiveHandler(person: PersonListItem) {
    if (!person.id || person.activationStatus !== ActivationStatus.Archived) return;

    props.setLoadingPeople([...props.loadingPeople, person.id]);
    await actions.unarchivePerson(person.id);
    props.setLoadingPeople([...props.loadingPeople.filter(pid => person.id !== pid)]);
  }

  function generateKebabMenuItems(person: PersonListItem): MenuItem[] {
    const kebabMenuItems: MenuItem[] = [
      {
        label: 'Manage',
        onClick: () => onClickPersonHandler(person.id),
      },
      {
        label: 'Archive',
        onClick: () => onClickArchiveHandler(person),
      },
    ];

    switch (person.activationStatus) {
      case ActivationStatus.Archived:
        kebabMenuItems.pop();
        kebabMenuItems.push({
          label: 'Unarchive',
          onClick: () => onClickUnarchiveHandler(person),
        });
        break;

      case ActivationStatus.NotInvited:
        kebabMenuItems.push({
          label: 'Send activation invitation',
          onClick: () => props.onClickSendActivationInvitation({ emails: [person.email!], shouldClearRows: false }),
        });
        break;

      case ActivationStatus.Inactive:
        kebabMenuItems.push({
          label: 'Send activation reminder',
          onClick: () => props.onClickSendActivationReminder({ emails: [person.email!], shouldClearRows: false }),
        });
        break;
    }

    return kebabMenuItems;
  }

  return (
    <Table
      selectableRows
      currentSortBy={String(props.request.sortField)}
      onSort={onSortHandler}
      currentSortDirection={props.request.sortAscending ? 'Ascending' : 'Descending'}
      selectedRows={props.selectedRows}
      onRowSelection={props.setSelectedRows}
      emptyState={
        <EmptyState
          title="No one has been added yet"
          buttons={[
            {
              text: 'Bulk Upload',
              onClick: () => {
                keplerNavigate(`${PagePath.administrationBase}${PagePath.administrationPeopleBulkUpload}`);
              },
            },
          ]}
        />
      }
      onConfigClicked={() => props.setManageColumns({ open: true })}
      footerContent={
        <PagerNavigationConnector onPageChange={onPageChangeHandler} defaultPageNumber={props.request.page ?? 1} pageCount={props.data?.totalPages ?? 1}>
          {connector => {
            return <Pager {...connector} />;
          }}
        </PagerNavigationConnector>
      }
    >
      {props.data?.employees.map(person => {
        const isLoading = props.loadingPeople.includes(person.id!);

        return (
          <TableRow
            configCell={() => {
              return (
                <span key={person.id} style={{ visibility: isLoading ? 'hidden' : undefined, pointerEvents: isLoading ? 'none' : undefined }}>
                  <KebabMenu items={generateKebabMenuItems(person)} />
                </span>
              );
            }}
            id={person.id!}
            key={person.id}
            isLoading={isLoading}
          >
            <TableColumn id="Name" label="Name" sortable>
              <PeopleTableNameCellContentContainer>
                <PersonActivationStatusIcon status={person.activationStatus} />
                {person.firstName} {person.lastName}
              </PeopleTableNameCellContentContainer>
            </TableColumn>

            <TableColumn sortable hidden={!props.columnConfiguration.find(column => column.key === 'Department')?.selected} id="Department" label="Department">
              {!!person.department.slug && (
                <Anchor
                  hovertype="opacity"
                  textTransform="none"
                  onClick={event => {
                    event.stopPropagation();
                    keplerNavigate(createDepartmentLink(props.companySlug!, person.department.slug!));
                  }}
                >
                  {person.department.name}
                </Anchor>
              )}

              {!person.department.slug && <React.Fragment>{person.department.name}</React.Fragment>}
            </TableColumn>

            <TableColumn sortable hidden={!props.columnConfiguration.find(column => column.key === 'Team')?.selected} id="Team" label="Team">
              {!!person.team.slug && (
                <Anchor
                  hovertype="opacity"
                  textTransform="none"
                  onClick={event => {
                    event.stopPropagation();
                    keplerNavigate(createTeamLink(props.companySlug!, person.team.slug!));
                  }}
                >
                  {person.team.name}
                </Anchor>
              )}

              {!person.team.slug && <React.Fragment>{person.team.name}</React.Fragment>}
            </TableColumn>

            <TableColumn sortable hidden={!props.columnConfiguration.find(column => column.key === 'Email')?.selected} id="Email" label="Email">
              {isMobile && person.email.length > 14 ? `${person.email.slice(0, 14)}...` : person.email}
            </TableColumn>

            <TableColumn sortable hidden={!props.columnConfiguration.find(column => column.key === 'Country')?.selected} id="Country" label="Country">
              {person.country.name}
            </TableColumn>

            {isLoading ? (
              <TableColumn hidden={!props.columnConfiguration.find(column => column.key === 'activationStatus')?.selected} id="activationStatus" label="Status">
                <Chip label="Updating" variant="tiny" backgroundColour="a" colour="white" />
              </TableColumn>
            ) : (
              <TableColumn hidden={!props.columnConfiguration.find(column => column.key === 'Status')?.selected} id="Status" label="Status">
                {person.activationStatus === ActivationStatus.Active && (
                  <Chip label="Account active" variant="tiny" backgroundColour="g" colour="white" />
                )}

                {person.activationStatus === ActivationStatus.Inactive && (
                  <Chip label="Activation required" variant="tiny" backgroundColour="a" colour="white" />
                )}

                {person.activationStatus === ActivationStatus.Archived && (
                  <Chip label="Archived" variant="tiny" colour="text" />
                )}

                {person.activationStatus === ActivationStatus.NotInvited && (
                  <Chip label="Invite pending" variant="tiny" backgroundColour="r" colour="white" />
                )}
              </TableColumn>
            )}

            <TableColumn sortable hidden={!props.columnConfiguration.find(column => column.key === 'DateCreated')?.selected} id="DateCreated" label="Date Created">
              {!!person.dateCreated && new Date(person.dateCreated).toLocaleString('en-UK', { dateStyle: 'long' })}
            </TableColumn>
          </TableRow>
        );
      })}
    </Table>
  );
}

