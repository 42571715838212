import { useEffect } from 'react';
import React from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { PermissionsEntity } from '../permissions-entity-dropdown/permissions-entity-dropdown.models';
import { EntityScoresSearchParams } from '../../../models/overmind/search-params';
import { OrganizationLevelType, ScoreType } from '../../../enums';
import { HorizontalBarChart } from './horizontal-bar-chart/horizontal-bar-chart';
import { SkeletonLoader } from '../../../components';
import { ScoreItem } from './horizontal-bar-chart/horizontal-bar-chart.models';
import { EntityScoreResult } from '../../../models/overmind/analytics';
import classNames from 'classnames';
import styles from './ranked-entities.module.css';

export default function RankedEntitiesWidget({ entity }: { entity: PermissionsEntity | undefined }): JSX.Element {

  const { companyVariables, dateRange } = useAppState();

  const actions = useAppActions();

  const [highestScores, setHighestScores] = React.useState<ScoreItem[]>([]);
  const [lowestScores, setLowestScores] = React.useState<ScoreItem[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      if (!entity) return;

      setIsLoading(true);

      const startDate = dateRange?.from?.toJSON();
      const endDate = dateRange?.to?.toJSON();
      const organizationLevel = entity.organizationLevel;
      const slug = entity.entity?.slug;


      const searchParams: EntityScoresSearchParams = {
        startDate,
        endDate,
        organizationLevel: OrganizationLevelType.Learner,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        page: 1,
        pageSize: 5,
        scoreType: ScoreType.SkillType,
        scoreTypeIds: ['Overall'],
        sortAscending: undefined,
      };

      if (organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = slug;
      if (organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = slug;
      if (organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug = slug;

      const rankedResults = await actions.analyticsGetRankedScores(searchParams);
      setHighestScores(transformScores(rankedResults?.highest ?? []));
      setLowestScores(transformScores(rankedResults?.lowest ?? []));

      setIsLoading(false);
    }

    getData();

    function transformScores(scores: EntityScoreResult[]): ScoreItem[] {
      return scores.map((personScore) => {
        const personScoresObj: ScoreItem = { name: personScore.name };
        personScore.scores.forEach((score) => {
          personScoresObj[score.name] = companyVariables.useLevels ? score.score.level : score.score.percentage;
        });
        return personScoresObj;
      });
    }

  }, [entity, dateRange]);

  return (
    <div className={classNames('row', styles.widgetContainer)}>
      {isLoading ? (
        <>
          <SkeletonLoader height='450px' />
          <SkeletonLoader height='450px' />
        </>
      ) : (
        <>
          {!!highestScores.length && (
            <div className={classNames('column', 'card')}>
              <h3 className="heading3">Highest rated people</h3>
              <HorizontalBarChart scores={highestScores} type='highest' />
            </div>
          )}
          {!!lowestScores.length && (
            <div className={classNames('column', 'card')}>
              <h3 className="heading3">Lowest rated people</h3>
              <HorizontalBarChart scores={lowestScores} type='lowest' />
            </div>
          )}
        </>
      )}
    </div>
  );
}