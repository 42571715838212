import React, { useEffect, useState } from 'react';
import { AssignEntities } from './assign-entities/assign-entities';
import { FocusPanelLoaderLayer } from '../../../../../../components/loading-handling/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { OrganizationLevelType, FetchType } from '../../../../../../enums';
import { extractHighestOrganizationLevel, extractOrganizationLevel } from '../../../../../../lib/permissions.helpers';
import { UniqueEntity, BaseSearchRequest } from '../../../../../../models';
import { OrganizationLevel, Permission } from '../../../../../../models/overmind/permissions';
import { useAppState, useAppActions } from '../../../../../../overmind';
import { IManagePersonPermissionFocusPanelProps } from '../manage-person-panels.models';
import { PagePath } from '../../../../../../navigation/navigation.enums';
import { Anchor, Button, ListItemLayout, ListLayout, RadioButton } from '@keplerco/core';
import { PermissionType } from '../../../../../../enums/permission-type';

export function ManagePersonPermissionFocusPanel(props: IManagePersonPermissionFocusPanelProps): JSX.Element {
  const { companyVariables } = useAppState();
  const actions = useAppActions();

  const [organizationLevel, setOrganizationLevel] = useState<OrganizationLevel>({
    organizationLevel: OrganizationLevelType.Learner,
    entities: [],
  });
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const organizationLevel = extractHighestOrganizationLevel(props.permission?.organizationLevels);
    if (!organizationLevel || organizationLevel.organizationLevel === OrganizationLevelType.Learner) {
      setOrganizationLevel({
        organizationLevel: OrganizationLevelType.Learner,
        entities: [],
      });
      return;
    }

    setOrganizationLevel(organizationLevel);
  }, [props.permission]);

  async function onFuzzySearch(payload: { type: 'Department' | 'Team'; query: string }): Promise<UniqueEntity[] | undefined> {
    actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.Custom });

    const request: BaseSearchRequest = { companySlug: companyVariables.slug, search: payload.query, pageSize: 10 };
    let data: UniqueEntity[] | undefined = undefined;
    switch (payload.type) {
      case 'Department': {
        data = await onFuzzySearchDepartments(request);
        break;
      }

      case 'Team': {
        data = await onFuzzySearchTeams(request);
        break;
      }
    }

    actions.stopLoader(PagePath.administrationPeople);
    return data;
  }

  async function onFuzzySearchDepartments(request: BaseSearchRequest): Promise<UniqueEntity[] | undefined> {
    const data = await actions.searchDepartments(request);
    return data?.departments.map(department => ({ name: department.name, slug: department.slug }));
  }

  async function onFuzzySearchTeams(request: BaseSearchRequest): Promise<UniqueEntity[] | undefined> {
    const data = await actions.searchTeams(request);
    return data?.teams.map(team => ({ name: team.teamName, slug: team.teamSlug }));
  }

  function onClickAdd(nextEntity: UniqueEntity) {
    const currentEntityIndex = organizationLevel.entities.findIndex(currentEntity => currentEntity.slug === nextEntity.slug);
    if (currentEntityIndex !== -1) return;
    setError(false);
    setOrganizationLevel(currentState => {
      const nextState: OrganizationLevel = structuredClone(currentState);
      nextState.entities.push(nextEntity);
      return nextState;
    });
  }

  function onClickRemove(nextEntity: UniqueEntity) {
    const currentEntityIndex = organizationLevel.entities.findIndex(currentEntity => currentEntity.slug === nextEntity.slug);
    if ((!currentEntityIndex && currentEntityIndex !== 0) || currentEntityIndex === -1) return;
    setOrganizationLevel(currentState => {
      const nextState: OrganizationLevel = structuredClone(currentState);
      nextState.entities.splice(currentEntityIndex, 1);
      return nextState;
    });
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationPeople}>
      <div className="panelContent">
        <header className="dialogHeaderLayout">
          <h2 className="heading2">{props.name}</h2>
        </header>

        <div className="dialogBodyLayout">
          <ListLayout>
            <ListItemLayout>
              <RadioButton
                id="permissionEmpty"
                clickablearea="label"
                alignitems="center"
                checked={organizationLevel.organizationLevel === OrganizationLevelType.Learner}
                onChange={() => {
                  setOrganizationLevel({
                    organizationLevel: OrganizationLevelType.Learner,
                    entities: [],
                  });
                }}
              >
                No Access
              </RadioButton>
            </ListItemLayout>

            <ListItemLayout>
              <RadioButton
                id="permissionWholeCompany"
                clickablearea="label"
                alignitems="center"
                checked={organizationLevel.organizationLevel === OrganizationLevelType.Company}
                onChange={() => {
                  setOrganizationLevel({
                    organizationLevel: OrganizationLevelType.Company,
                    entities: [props.company!],
                  });
                }}
              >
                Whole company
              </RadioButton>
            </ListItemLayout>

            <ListItemLayout>
              <RadioButton
                id="permissionDepartment"
                clickablearea="label"
                alignitems="center"
                checked={organizationLevel.organizationLevel === OrganizationLevelType.Department}
                onChange={() => {
                  setError(false);

                  const departmentOrganizationLevel = extractOrganizationLevel(props.permission?.organizationLevels, OrganizationLevelType.Department);
                  if (!!departmentOrganizationLevel?.entities) {
                    setOrganizationLevel({
                      organizationLevel: OrganizationLevelType.Department,
                      entities: departmentOrganizationLevel.entities,
                    });
                    return;
                  }

                  const autofillEntities: UniqueEntity[] = [];
                  if (!!props.person?.departmentSlug) autofillEntities.push({ name: props.person.departmentName, slug: props.person.departmentSlug.toString() });
                  setOrganizationLevel({
                    organizationLevel: OrganizationLevelType.Department,
                    entities: autofillEntities,
                  });
                }}
              >
                Department
              </RadioButton>

              {organizationLevel.organizationLevel === OrganizationLevelType.Department && <AssignEntities type="Department" assignedEntities={organizationLevel.entities} error={error} onFuzzySearch={onFuzzySearch} onClickAdd={onClickAdd} onClickRemove={onClickRemove} />}
            </ListItemLayout>

            <ListItemLayout>
              <RadioButton
                id="permissionTeam"
                clickablearea="label"
                alignitems="center"
                checked={organizationLevel.organizationLevel === OrganizationLevelType.Team}
                onChange={() => {
                  setError(false);

                  const teamOrganizationLevel = extractOrganizationLevel(props.permission?.organizationLevels, OrganizationLevelType.Team);
                  if (!!teamOrganizationLevel?.entities) {
                    setOrganizationLevel({
                      organizationLevel: OrganizationLevelType.Team,
                      entities: teamOrganizationLevel.entities,
                    });
                    return;
                  }

                  const autofillEntities: UniqueEntity[] = [];
                  if (!!props.person?.teamSlug) autofillEntities.push({ name: props.person.teamName, slug: props.person.teamSlug.toString() });
                  setOrganizationLevel({
                    organizationLevel: OrganizationLevelType.Team,
                    entities: autofillEntities,
                  });
                }}
              >
                Team
              </RadioButton>

              {organizationLevel.organizationLevel === OrganizationLevelType.Team && <AssignEntities type="Team" assignedEntities={organizationLevel.entities} error={error} onFuzzySearch={onFuzzySearch} onClickAdd={onClickAdd} onClickRemove={onClickRemove} />}
            </ListItemLayout>
          </ListLayout>
        </div>

        <footer className="panelFooter">
          <Anchor onClick={props.onCancel}>Cancel</Anchor>

          <Button
            type="button"
            filled
            arrow={false}
            onClick={async () => {
              if (!props.person || !props.permission || !organizationLevel || (organizationLevel.organizationLevel !== OrganizationLevelType.Learner && organizationLevel.entities.length === 0)) return setError(true);

              actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.Sending });

              const permission: Permission = {
                ...props.permission,
                organizationLevels: [organizationLevel],
              };
              await actions.assignPermission({ companySlug: companyVariables.slug!, personSlug: props.person.learnerSlug, permission });
              // TODO: temporary hack, needs to be removed on completion of KPRDEV-1824
              if ([PermissionType.RoleManagement, PermissionType.Assessments].includes(props.permission.permissionType)) {
                const skillsPermission: Permission = {
                  permissionType: PermissionType.Skills,
                  organizationLevels: [organizationLevel],
                };
                await actions.assignPermission({ companySlug: companyVariables.slug!, personSlug: props.person.learnerSlug, permission: skillsPermission });
              }

              props.onSave();

              actions.stopLoader(PagePath.administrationPeople);
            }}
          >
            Save
          </Button>
        </footer>
      </div>
    </FocusPanelLoaderLayer>
  );
}

